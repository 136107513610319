import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';
import { selectCardListEntities } from '../../store/selectors/card.selector';
import { TaskCreationDialog } from '../task-creation-dialog/task-creation-dialog.component';

@Component({
  selector: 'app-product-backlog',
  templateUrl: './product-backlog.component.html',
  styleUrls: ['./product-backlog.component.scss'],
})
export class ProductBacklogComponent implements OnInit {
  constructor(private store: Store, public dialog: MatDialog) {}
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public cardListEntities: any;
  public cardList: any;

  ngOnInit(): void {
    this.store
      .pipe(select(selectCardListEntities), takeUntil(this.destroyed$))
      .subscribe((entities) => {
        const localCardList = [];
        Object.keys(entities).forEach((key: string) => {
          if (entities[key].type === 'TASK' && !entities[key].sprint_id) {
            localCardList.push(entities[key]);
          }
        });
        this.cardList = localCardList.sort((item1: any, item2: any) => {
          if (item1.title > item2.title) {
            return 0;
          }
          return 1;
        });
        this.cardListEntities = entities;
      });
  }

  openNewTaskDialog() {
    this.dialog.open(TaskCreationDialog, {
      data: {},
      width: '450px',
    });
  }
}

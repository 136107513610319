import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() public type: string
  public ASSETS_PATH = '../assets/'

  constructor() { }

  ngOnInit(): void {
  }

}

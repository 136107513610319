import { IProfileResponse } from '.'

export const CustomFieldsType = {
  TEXT: 'text',
  NUMBER: 'number',
  PHONE: 'phone',
  DATE: 'date',
  URL: 'url',
  EMAIL: 'email',
}
export interface IOrgRequest {
  domain?: string
  newDomain?: string
  email?: string
  name?: string
  whitelistedDomains?: string[]
  public?: boolean
  guestSettings?: String
  id: string
  iconKey?: string
  iconUrl?: string
  customFields?: any
}

export interface IOrgResponse extends IOrgRequest {
  createdAt?: String
  createdBy?: String
  disabled?: boolean
  users?: IProfileResponse[]
  org_user?: []
  authKey?: String
  updatedAt?: String
  updatedBy?: String
}

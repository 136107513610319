import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISprintResponse } from '@app/src/app/models/interfaces/sprint.interface';
import { createSprint } from '@app/src/app/store/actions/sprint.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sprint-creation-dialog',
  templateUrl: 'sprint-creation-dialog.html',
  styleUrls: ['./sprint-creation-dialog.scss'],
})
export class SprintCreationDialog {
  public addSprintForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<SprintCreationDialog>,
    private formBuilder: FormBuilder,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInIt(): void {}

  onCancleClick(): void {
    this.dialogRef.close();
  }

  saveDetails() {
    if (this.addSprintForm.status !== 'VALID') {
      return;
    }
    let createSprintPayload = this.addSprintForm.value;
    if (this.isDateColliding(createSprintPayload)) {
      return;
    }
    createSprintPayload.start_date = createSprintPayload.startDate;
    createSprintPayload.end_date = createSprintPayload.endDate;
    createSprintPayload.project_id = this.data.activeProjectId;
    delete createSprintPayload.startDate;
    delete createSprintPayload.endDate;
    this.store.dispatch(createSprint({ model: createSprintPayload }));
    this.dialogRef.close();
  }
  private isDateColliding(currentSprint: any) {
    if (new Date(currentSprint.startDate) >= new Date(currentSprint.endDate)) {
      alert('Sprint end date must be after its starting date');
      return true;
    }
    const currentSprintStartDate = new Date(currentSprint.startDate);
    const currentSprintEndDate = new Date(currentSprint.endDate);
    // returns true if even a single existing
    // sprint collide with the new sprint
    return !this.data.sprintList.every((sprint: ISprintResponse) => {
      if (
        !(
          (currentSprintStartDate >= new Date(sprint.start_date) &&
            currentSprintStartDate <= new Date(sprint.end_date)) ||
          (currentSprintEndDate >= new Date(sprint.start_date) &&
            currentSprintEndDate <= new Date(sprint.end_date))
        )
      ) {
        return true;
      }

      alert('A sprint with colliding dates already exist');
      return false;
    });
  }
  public async transformDate(date: any) {
    if (date) {
      const dateArray = date?.split('T');
      return dateArray[0];
    }
  }
}

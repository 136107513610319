<div>
    <form [formGroup]="formGroup">
        <mat-form-field appearance="fill">
          <input 
                  formControlName="employee"
                   matInput type="text" 
                   name="memberValue"
                  [(ngModel)]="memberValue"
                  matInput
                  class="member-value"
                  placeholder="{{userEntities[dataValue]?.firstName}} {{userEntities[dataValue]?.lastName}}"
                  [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of filteredOptions" [value]="item?._source.title" [id]="item" (onSelectionChange)="onOptionChange($event)">        
                      {{item?._source.title}}
                    </mat-option>
                  </mat-autocomplete>
        </mat-form-field>
  
      </form>
</div>

import { createAction, props } from '@ngrx/store'
import {  RealTimeResponse, IProjectPropertiesResponse } from '../../models'

export const loadAllProjectProperties = createAction(
  '[project_properties] Load all Project Properties',
  props<{ projectId: string }>()
)

export const loadAllProjectPropertiesSuccess = createAction(
  '[project_properties] Load all Projects Properties Success',
  props<{ payload: RealTimeResponse<IProjectPropertiesResponse[]> }>()
)

export const loadAllProjectPropertiesFailure = createAction(
  '[project] Load all Projects Failure',
  props<{ error: any }>()
)

export const createProjectProperties = createAction(
  '[project_properties] Create a project properties',
  props<{ projectId: string, isStage: boolean,  body: any}>()
)

export const createProjectPropertiesSuccess = createAction(
  '[project_properties] Create project propertiessuccess',
  props<{ payload: RealTimeResponse<any> }>()
)

export const createProjectPropertiesFailure = createAction(
  '[project_properties] Create project properties Failure',
  props<{ error: any }>()
)

export const updateProjectPropertyPosition = createAction(
  '[project_properties] Update project properties position',
  props<{ projectId: string, body: any}>()
)

export const updateProjectProperties = createAction(
  '[project_properties] Update project properties',
  props<{ propertyId: string, projectId: string, body: any }>()
)

export const updateProjectPropertiesSuccess = createAction(
  '[project_properties] Update project properties success',
  props<{ payload: RealTimeResponse<any> }>()
)

export const updateProjectPropertiesFailure = createAction(
  '[project_properties] Update project Properties Failure',
  props<{ error: any }>()
)

export const deleteProjectProperties = createAction(
  '[project_properties] Delete project properties',
  props<{ propertyId: string, projectId: string }>()
)

export const deleteProjectPropertiesSuccess = createAction(
  '[project_properties] Delete project properties success',
  props<{ payload: RealTimeResponse<any> }>()
)

export const deleteProjectPropertiesFailure = createAction(
  '[project_properties] Delete project Properties Failure',
  props<{ error: any }>()
)

export const createProjectMember = createAction(
  '[project_properties] Delete project Properties Failure',
  props<{ error: any }>()
)
import {
  createFeatureSelector,
  createSelector,
  defaultMemoize,
} from '@ngrx/store';
import * as CardReducer from '../reducers/card.reducer';
import { getSelectors } from '@ngrx/router-store';
import { selectActiveOrgId, selectActiveProjectId, selectActiveSprintId } from '../reducers';

export const { selectAll, selectEntities } = CardReducer.adapter.getSelectors();

export const selectCardState = createFeatureSelector<CardReducer.CardState>(
  CardReducer.cardFeatureKey
);

export const selectCard = createSelector(selectCardState, selectAll);

export const selectCardIds = createSelector(
  selectCardState,
  (state) => state.ids
);

export const selectCardListEntities = createSelector(
  selectCardState,
  selectEntities
);

export const selectFeatureByModuleId = createSelector(
  selectCardState,
  (state) => {
    const featureByModuleId: any = {};

    for (let id of state?.ids) {
      const card = state?.entities[id];
      if (card && card.type === 'FEATURE') {
        if (featureByModuleId[card.parent_id]) {
          featureByModuleId[card.parent_id].push(card.id);
        } else {
          featureByModuleId[card.parent_id] = [card.id];
        }
      }
    }
    return featureByModuleId;
  }
)

export const selectNewCardId = createSelector(
  selectCardState,
  (state) => {
    return state.newCard?.id
  }
)

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors()

export const selectCardByFeatureId = createSelector(
  selectCardState,
  (state) => {
    const cardByFeatureId: any = {};

    for (let id of state?.ids) {
      const card = state?.entities[id];
      if (card && card.type === 'TASK') {
        if (cardByFeatureId[card.parent_id]) {
          cardByFeatureId[card.parent_id].push(card.id);
        } else {
          cardByFeatureId[card.parent_id] = [card.id];
        }
      }
    }

    return cardByFeatureId;
  }
);

const _selectActiveCard = (cardId: string) => {
  return createSelector(selectCardListEntities, (card: any) => {
    if (card && cardId) {
      return card[cardId];
    }
    return null;
  });
};

export const selectActiveCard = (cardId: string) =>
  defaultMemoize(_selectActiveCard).memoized(cardId);
  
export const selectCardBySprintId = createSelector(
  selectCardState,
  selectActiveSprintId,
  (state, sprintId) => {
    const cardBySprintId: any = [];

    for (let id of state?.ids) {
      const card = state?.entities[id];
      if (card.sprint_id == sprintId) {
        cardBySprintId.push(card);
      }
    }
    return cardBySprintId;
  }
);

export const selectActiveProjectAndOrg = createSelector(
  selectActiveProjectId,
  selectActiveOrgId,
  (projectId, orgId) => {
    return { projectId, orgId };
  }
);
<img
  [ngStyle]="{
    'border-radius': round ? '50%' : '0',
    width: fitWidth ? '100%' : size,
    height: autoHeight ? 'auto' : size
  }"
  [src]="imageUrl ? imageUrl : fallBackDpPath"
  [height]="size"
  [width]="size"
/>

import { Component, Input, OnInit } from '@angular/core';
import { IProjectResponse } from '../../models';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import {
  selectActiveOrgId,
  selectActiveProjectId,
  selectActiveSprintId,
} from '../../store/reducers';
import {
  selectIdOfActiveSprint,
  selectSprintById,
  selectSprintList,
  selectSprintListEntities,
} from '../../store/selectors/sprint.selector';
import {
  loadSprintById,
  setActiveSprint,
} from '../../store/actions/sprint.actions';
import { selectProjectListEntities } from '../../store/selectors/project.selectors';
import { ISprintResponse } from '../../models/interfaces/sprint.interface';
import { LocalService } from '../../store/services/local.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() projectList: IProjectResponse[];
  public project;
  public selected: String = '';
  public activeOrgId: string;
  public activeSprintId: any;
  public activeProjectId: string;
  public projectListEnities: { [id: string]: IProjectResponse };
  public sprintListEntities: { [id: string]: ISprintResponse };
  public sidebarCollapsed: boolean;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private store: Store,
    private router: Router,
    private localService: LocalService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectSprintListEntities), takeUntil(this.destroyed$))
      .subscribe((enitites) => {
        this.sprintListEntities = enitites;
      });
    this.localService.sidebarCollapsed$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sidebarCollapsed) => {
        this.sidebarCollapsed = sidebarCollapsed;
      });
    this.store
      .pipe(select(selectProjectListEntities), takeUntil(this.destroyed$))
      .subscribe((enities) => {
        this.projectListEnities = enities;
      });
    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((projectId) => {
        if (projectId) {
          this.activeSprintId = undefined;
          this.project = this.projectListEnities[projectId];
          this.activeProjectId = this.projectListEnities[projectId].id
        }
      });
    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      });

    this.store
      .pipe(select(selectIdOfActiveSprint), takeUntil(this.destroyed$))
      .subscribe(async (sprintId) => {
        this.activeSprintId = sprintId;
      });

    this.store
      .pipe(select(selectSprintList), takeUntil(this.destroyed$))
      .subscribe(async (sprintList) => {
        let tempSprintList = [];
        let date = new Date();
        let todayDate: any = await this.transformDate(date.toISOString());
        for (let sprint of sprintList) {
          sprint = { ...sprint };
          sprint.start_date = await this.transformDate(sprint.start_date);
          sprint.end_date = await this.transformDate(sprint.end_date);
          if (todayDate >= sprint.start_date && todayDate <= sprint.end_date) {
            tempSprintList.push(sprint);
          }
        }
        this.activeSprintId = this.activeSprintId || tempSprintList[0]?.id;
      });

    this.store
      .pipe(select(selectSprintById), takeUntil(this.destroyed$))
      .subscribe(async (activeSprint) => {
        if (!activeSprint && this.activeSprintId) {
          this.store.dispatch(
            loadSprintById({ sprintId: this.activeSprintId })
          );
        }
      });
  }

  public selectProduct(name) {
    this.selected = name;
  }

  public openProjectPage() {
    this.router.navigate([`orgs/${this.activeOrgId}/`]);
  }

  public openModulePage() {
    this.router.navigate([
      `orgs/${this.activeOrgId}/project/${this.project?.id}/sprints`,
    ]);
  }

  public openSprintBacklog() {
    this.router.navigate([
      `orgs/${this.activeOrgId}/project/${this.project?.id}/sprint/${this.activeSprintId}`,
    ]);
  }

  public openGrommingSession() {
    this.router.navigate([
      `orgs/${this.activeOrgId}/gromming-session/project/${this.project?.id}/sprint/${this.activeSprintId}`,
    ]);
  }

  public openProductBacklog() {
    this.router.navigate([
      `orgs/${this.activeOrgId}/product-backlog/project/${this.project?.id}`,
    ]);
  }

  public async transformDate(date: any) {
    if (date) {
      const dateArray = date.split('T');
      return dateArray[0];
    }
  }
  public openSettingsPage() {
    this.router.navigate([
      `orgs/${this.activeOrgId}/settings/${this.project?.id}`,
    ]);
  }

  public redirectToAllPrd() {
    this.router.navigate([
      `orgs/${this.activeOrgId}/project/${this.project?.id}`,
    ]);
  }

  public parseSprintDates(): string {
    if (!this.activeSprintId) {
      return 'No Sprint Running';
    }
    const startDate = new Date(
      this.sprintListEntities[this.activeSprintId]?.start_date
    );
    const endDate = new Date(
      this.sprintListEntities[this.activeSprintId]?.end_date
    );

    return `${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`;
  }

  public toggleSidebarCollapse() {
    this.localService.toggleSidebarCollapsed(!this.sidebarCollapsed);
  }
}

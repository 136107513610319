import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IProjectPropertiesResponse } from '@app/models';
import * as ProjectUserActions from '../actions/projectUser.actions';
import { IProjectUserResponse } from '../../models/interfaces/project-user.interface';

export const projectUserFeatureKey = 'project_user';

export interface ProjectUserState
  extends EntityState<IProjectUserResponse> {
  loading: boolean;
  isLoaded: boolean;
}

export const adapter: EntityAdapter<IProjectUserResponse> =
  createEntityAdapter<IProjectUserResponse>({
    sortComparer: sortByName,
  });

export function sortByName(
    a: IProjectUserResponse,
    b: IProjectUserResponse
  ): number {
    return a?.userId.localeCompare(b?.userId);
  }

export const initialState: ProjectUserState = adapter.getInitialState({
    loading: null,
    isLoaded: null,
  });

export const projectUserReducer = createReducer(
    initialState,

    on(ProjectUserActions.loadAllProjectUser, (state, action) => {
      if (!state?.ids?.length) {
        state = { ...state, loading: true, isLoaded: false };
      }
      return state;
    }),
  
    on(
      ProjectUserActions.loadAllProjectUserSuccess,
      (state, action) => {
        if (action.payload.data) {
          const data = Object.keys(action.payload.data).map((id: string) => ({
            id: id,
            ...action.payload.data[id],
          }));
          return adapter.setAll(data, state);
        }
        return state;
      }
    ),

    on(ProjectUserActions.createProjectUser, (state, action) => {
        if (!state?.ids?.length) {
          state = { ...state, loading: true, isLoaded: false };
        }
        return state;
      }),
    
      on(
        ProjectUserActions.createProjectUserSuccess,
        (state, action) => {
          return adapter.addOne(action.payload.data, state);
        }
      ),

      on(ProjectUserActions.deleteProjectUser, (state, action) => {
        if (!state?.ids?.length) {
          state = { ...state, loading: true, isLoaded: false };
        }
        return state;
      }),
    
      on(
        ProjectUserActions.deleteProjectUserSuccess,
        (state, action) => {
          return adapter.removeOne(action.payload.data.id, state);
        }
      ),
    
);
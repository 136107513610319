<div
  class="sidebar-wrapper"
  [ngClass]="{ 'sidebar-collapsed': sidebarCollapsed }"
>
  <div *ngIf="!sidebarCollapsed">
    <mat-list>
        <mat-list-item (click)="openProjectPage()" class="products-wrapper">
            <div class="project-name" matLine > {{projectListEnities[activeProjectId]?.title || 'Select Project'}}</div>
            <div matLine></div>
         <img
                style="display: inline; height: 30px"
                src="../../assets/img/navigate_next.svg"
                alt=""
              />
        </mat-list-item>
    </mat-list>
    <hr class="dropdown-divider" />
    <div *ngIf="project">
      <mat-list>
        <mat-list-item
          (click)="openModulePage()"
          class="products-wrapper"
          [ngClass]="{
            active: selected === sprintListEntities[activeSprintId]?.title
          }"
        >
          <div class="product-name" matLine>
            {{ sprintListEntities[activeSprintId]?.title || "Select Sprint" }}
          </div>
          <div class="product-sprint" matLine>
            {{ parseSprintDates() }}
          </div>
          <img
            style="display: inline; height: 30px"
            src="../../assets/img/navigate_next.svg"
            alt=""
          />
        </mat-list-item>
      </mat-list>

      <mat-list *ngIf="activeSprintId">
        <mat-list-item (click)="openSprintBacklog()" class="products-wrapper">
          <div matLine>Sprint Backlog</div>
        </mat-list-item>
        <mat-list-item (click)="openGrommingSession()" class="products-wrapper">
          <div matLine>Grooming Session</div>
        </mat-list-item>
        <mat-list-item class="products-wrapper">
          <div matLine>Daily Standup</div>
        </mat-list-item>
      </mat-list>
      <hr class="dropdown-divider" />
    </div>
    <mat-list *ngIf="project">
      <mat-list-item (click)="redirectToAllPrd()" class="products-wrapper">
        <div class="product-name" matLine>All PRDs</div>
      </mat-list-item>
      <mat-list-item (click)="openProductBacklog()" class="products-wrapper">
        <div class="product-name" matLine>Product Backlog</div>
      </mat-list-item>
      <mat-list-item (click)="openSettingsPage()" class="products-wrapper">
        <div class="product-name" matLine>Settings</div>
      </mat-list-item>
    </mat-list>
  </div>
  <div
    class="toggle-icon"
    [ngClass]="{ 'show-icon-sidebar-expanded': !sidebarCollapsed }"
    (click)="toggleSidebarCollapse()"
  >
    {{ sidebarCollapsed ? ">" : "<" }}
  </div>
</div>

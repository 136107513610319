<div class="main-body">
  <h3 class="heading">All Sprints</h3>
  <div class="add-sprint">
    <button
      mat-raised-button
      color="primary"
      (click)="openSprintCreationDialog()"
    >
      Add
    </button>
  </div>
  <mat-card
    *ngFor="let sprint of sprintList"
    (click)="redirectToSprint(sprint.project_id, sprint.id)"
    [style.background]="
      sprint.start_date <= todayDate && sprint.end_date >= todayDate
        ? 'lightgreen'
        : 'white'
    "
  >
    <div class="title">
      {{ sprint.title }} <mat-icon class="arrow-icon">trending_flat</mat-icon>
    </div>
    <div class="date">{{ sprint.start_date }} - {{ sprint.end_date }}</div>
  </mat-card>
</div>

<ng-container>
  <h3 class="header">Property</h3>
  <div class="container">
    <div>
      <mat-form-field>
        <mat-label>Property Name</mat-label>
        <input
          (change)="updateName($event)"
          matInput
          [value]="property?.title"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Select Type</mat-label>
        <mat-select
          [value]="property?.type"
          (selectionChange)="updateType($event)"
          matNativeControl
          required
        >
          <ng-container *ngFor="let type of properties">
            <mat-option [value]="type?.toLowerCase()">{{ type }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="closeDialog()">
      Save
    </button>
  </div>
</ng-container>

import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as OrgReducer from '../reducers/org.reducer'

export const { selectAll, selectEntities } = OrgReducer.adapter.getSelectors()

export const selectOrgListState = createFeatureSelector<OrgReducer.OrgState>(
  OrgReducer.orgFeatureKey
)

export const selectOrgList = createSelector(selectOrgListState, selectAll)

export const selectOrgListEntities = createSelector(
  selectOrgListState,
  selectEntities
)

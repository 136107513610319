
export const environment = {
  "production": false,
  "environment": "dev",
  "AUTH_UI_URL": "https://dev-auth.intospace.io",
  "CHAT_UI_URL": "https://dev-channel.intospace.io",
  "INBOX_UI_URL": "https://dev-inbox.intospace.io",
  "OKR_UI_URL": "https://dev-okr.intospace.io",
  "AUTH_SERVER_URL": "https://dev-api.intospace.io",
  "SPRINT_SERVER_URL": "https://dev-api.intospace.io/sprint",
  "CLOUD_BASE_URL": "resources.intospace.io",
  "RTC_URL": "wss://dev-api.intospace.io/rtc",
  "DELVE_API_KEY": "TmkzBMbr3Z1eiLjMOQ0kqhqp4f0GVCzR1w"
};

<div class="card-detail-header">
  <div class="card-detail-header-content">
    <div class="card-detail-title">
      <input
        type="text"
        [(ngModel)]="activeCard.title"
        (keyup)="titleUpdate()"
      />
    </div>
    <div
      class="card-detail-more-option-dropdown"
      ngbDropdown
      placement="bottom-right"
    >
      <div ngbDropdownToggle class="card-detail-more-option">
        <i class="fas fa-ellipsis-v"></i>
      </div>
    </div>
  </div>
</div>
<div class="detail-container">
  <div class="card-detail">
    <div *ngIf="activeCard?.parent_id" class="card-detail-tab">
      <div>Project</div>
      <span> {{ projectEntities[activeCard.project_id].title }} </span>
    </div>
    <div
      *ngIf="activeCard?.parent_id && activeCard?.type === 'TASK'"
      class="card-detail-tab"
    >
      <div>Module</div>
      <span>{{
        moduleListEntities[featuresListEnities[activeCard?.parent_id]?.parent_id]
          ?.title
      }}</span>
    </div>
  
    <div *ngIf="activeCard?.type === 'FEATURE'" class="card-detail-tab">
      <div>Module</div>
      <mat-form-field appearance="fill">
        <mat-select
          [value]="selectedFeature"
          (selectionChange)="onFeatureChange($event)"
        >
          <mat-option
            [value]="option.key"
            *ngFor="let option of moduleListEntities | keyvalue"
            >{{ option.value.title }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="activeCard?.type === 'TASK'" class="card-detail-tab">
      <div>Feature</div>
      <mat-form-field appearance="fill">
        <mat-select
          [value]="selectedFeature"
          (selectionChange)="onFeatureChange($event)"
        >
          <mat-option
            [value]="option.key"
            *ngFor="let option of featuresListEnities | keyvalue"
            >{{ option.value.title }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="activeCard?.type === 'TASK'" class="card-detail-tab">
      <div>Assignee</div>
      <form [formGroup]="formGroup">
      <mat-form-field appearance="fill">
        <input 
                formControlName="employee"
                 matInput type="text" 
                 name="memberValue"
                [(ngModel)]="memberValue"
                matInput
                class="member-value"
                placeholder="{{selectedAssignee ? userEntities[activeCard?.assigned_to].firstName : ''}} {{selectedAssignee ? userEntities[activeCard?.assigned_to].lastName : ''}}"
                [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let item of filteredOptions" [value]="item?._source.title" [id]="item" (onSelectionChange)="onAssigneeChange($event)">
                    {{item?._source.title}}
                  </mat-option>
                </mat-autocomplete>
      </mat-form-field>
    
    </form>
    </div>
    <div *ngIf="activeCard?.type === 'TASK'" class="card-detail-tab">
      <div>Stage</div>
      <mat-form-field appearance="fill">
        <mat-select
          [value]="activeCard.stageId"
          (selectionChange)="onStageChange($event)"
        >
          <mat-option *ngFor="let stage of stages" [value]="stage.id">
            {{ stage.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="activeCard?.created_by" class="card-detail-tab">
      <div>Created By</div>
      <span
        >{{
          userEntities[activeCard.created_by].firstName +
            " " +
            userEntities[activeCard.created_by].lastName
        }}
      </span>
    </div>
    <div *ngIf="activeCard?.createdAt" class="card-detail-tab">
      <div>Created At</div>
      <span>{{ activeCard.createdAt }} </span>
    </div>
    
    <div *ngIf="activeCard?.type === 'TASK' && properties.length > 0"  class="show-more-button" (click)="toggleShowMore()">Show more</div>
    <div class="show-more-container" [ngClass]="{ 'show-container': showMore }">
      <div class="property-list">
        <ng-container *ngFor="let property of properties | keyvalue">
          <div class="card-detail-tab">
            <div class="">{{property?.value?.title}}</div>
            <app-input-type-property *ngIf="property.value.type == 'text' || property.value.type == 'number'" [inputData]="property.value" [dataValue]="activeCard.properties[property.value.id]" (submitValue)="updateCardProperty($event)"></app-input-type-property>
            <app-select-type-property *ngIf="property.value.type == 'select'" [inputData]="property.value" [dataValue]="activeCard.properties[property.value.id]" (submitValue)="updateCardProperty($event)"></app-select-type-property>
            <app-search-type-property *ngIf="property.value.type == 'people'" [inputData]="property.value" [dataValue]="activeCard.properties[property.value.id]" (submitValue)="updateCardProperty($event)"></app-search-type-property>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="editor-body">
      <space-rtc-editor
        [activeOrgId]="activeOrgId"
        [currentUser]="currentUser"
        [docName]="activeOrgId + '_' + activeCard.id + '_sprint'"
        [accessToken]="accessToken"
        [rtc_url]="rtcUrl"
      ></space-rtc-editor>
    </div>
  </div>
</div>
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as ProjectActions from '../actions/project.actions';
import { IProjectResponse } from '@app/models';

export const projectFeatureKey = 'project';

export interface ProjectState extends EntityState<IProjectResponse> {
  loading: boolean;
  isLoaded: boolean;
}

export const adapter: EntityAdapter<IProjectResponse> =
  createEntityAdapter<IProjectResponse>({
    sortComparer: sortByName,
  });

export function sortByName(a: IProjectResponse, b: IProjectResponse): number {
  return a?.title.localeCompare(b?.title);
}

export const initialState: ProjectState = adapter.getInitialState({
  loading: null,
  isLoaded: null,
});

export const projectReducer = createReducer(
  initialState,

  on(ProjectActions.loadSingleProject, (state) => {
    state = { ...state, loading: true, isLoaded: false };
    return state;
  }),

  on(ProjectActions.loadSingleProjectSuccess, (state, action) => {
    state = { ...state, loading: false, isLoaded: true };
    const project = action.payload.data;
    return adapter.addOne(project, state);
  }),

  on(ProjectActions.loadAllProjects, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(ProjectActions.loadAllProjectsSuccess, (state, action) => {
    const projectUsers = action.payload.data;
    const projects = [];
    if (projectUsers) {
      projectUsers.forEach((projectUser) => {
        projects.push(projectUser.project);
      });
      state = {
        ...state,
        loading: false,
        isLoaded: true,
      };
      return adapter.setAll(projects, state);
    }

    return state;
  }),

  on(ProjectActions.upsertProjectUserViaEvent, (state, action) => {
    const projectUser = action.payload;
    return adapter.upsertOne(projectUser.project, state);
  }),

  on(ProjectActions.upsertProjectViaEvent, (state, action) => {
    const project = action.payload;
    if(state.entities[project?.requestId]) {
      return adapter.updateOne({id: project?.requestId, changes: project}, state)
    }
    return adapter.upsertOne(project, state);
  }),

  on(ProjectActions.updateProjectViaEvent, (state, action) => {
    const project = action.payload;
    return adapter.updateOne({ id: project.id, changes: project }, state);
  }),

  on(ProjectActions.createProject, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    const data = {
      ...action.body,
      id: action.body.requestId,
    };
    delete data.requestId;
    return adapter.addOne(data, state);
  }),

  on(ProjectActions.createProjectSuccess, (state, action) => {
    state = {
      ...state,
    };
    return adapter.updateOne(
      { id: action.payload.data.requestId, changes: action.payload.data },
      state
    );
  }),

  on(ProjectActions.updateProject, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(ProjectActions.updateProjectSuccess, (state, action) => {
    state = {
      ...state,
    };
    return adapter.updateOne(
      { id: action.payload.data.id, changes: action.payload.data },
      state
    );
  })
);

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

import * as SprintActions from '../actions/sprint.actions';
import { ISprintResponse } from '../../models/interfaces/sprint.interface';

export const sprintFeatureKey = 'sprint';

export interface SprintState extends EntityState<ISprintResponse> {
  loading: boolean;
  isLoaded: boolean;
  updateInProgress: any;
  activeSprintId: string | null;
}

export const adapter: EntityAdapter<ISprintResponse> =
  createEntityAdapter<ISprintResponse>({
    sortComparer: sortByCreatedAt,
  });

export function sortByName(a: ISprintResponse, b: ISprintResponse): number {
  return a?.title.localeCompare(b?.title);
}

function sortByCreatedAt(a: ISprintResponse, b: ISprintResponse) {
  if (a?.start_date < b?.start_date) {
    return 1;
  } else {
    return a?.start_date > b?.start_date ? -1 : 0;
  }
}

export const initialState: SprintState = adapter.getInitialState({
  loading: null,
  isLoaded: null,
  updateInProgress: {},
  activeSprintId: null
});

export const sprintReducer = createReducer(
  initialState,

  on(SprintActions.loadAllSprints, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(SprintActions.loadAllSprintsSuccess, (state, action) => {
    const sprints = action.payload.data;
    // if (sprints?.length > 0) {
    // }
    state = {
      ...state,
      loading: false,
      isLoaded: true,
    };
    return adapter.setAll(sprints, state);

    return state;
  }),

  on(SprintActions.loadSprintById, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(SprintActions.loadSprintByIdSuccess, (state, action) => {
    const sprint: any = action.payload.data;
    if (sprint) {
      state = {
        ...state,
        loading: false,
        isLoaded: true,
      };
      const idArray: any = state.ids
      if(!idArray.indexOf(sprint.id)) {
        return adapter.setOne(sprint, state)
      }
    }

    return state;
  }),

  on(SprintActions.setActiveSprint, (state, action) => {
      console.log("yes in");
      state = { ...state, loading: true, isLoaded: false };
      state.activeSprintId = action.sprintId
    console.log("state has been updated: ", state);
    
    return state;
  }),

  on(SprintActions.createSprint, (state, action) => {
    const sprintModel = {
      ...action?.model,
      id: action?.model?.requestId,
    };
    if (sprintModel) {
      return adapter.addOne(sprintModel, state);
    }
    return state;
  }),

  on(SprintActions.createSprintSuccess, (state, action) => {
    let sprint = action?.payload?.data[0];
    if (sprint) {
      return adapter.updateOne(
        { id: sprint?.requestId, changes: sprint },
        state
      );
    }
    return state;
  }),

  on(SprintActions.createSprintFailure, (state, action) => {
    const sprint = (action as any)?.payload;
    if (sprint) {
      return adapter.removeOne(sprint?.requestId, state);
    }
    return state;
  }),

  on(SprintActions.updateSprint, (state, action) => {
    const sprintModel = { ...action?.model };
    const updateInProgress = {
      ...state?.updateInProgress,
      [action.id]: state.entities[action.id],
    };
    state = { ...state, updateInProgress };
    return adapter.updateOne(
      { id: action?.id, changes: sprintModel },
      state
    );
  }),
  on(SprintActions.upsertSprintViaEvent, (state, action) => {
    const sprint = action.payload;
    if(state.entities[sprint?.requestId]) {
      console.log("inside reducer 123: ", sprint, state); 
      return adapter.updateOne({id: sprint?.requestId, changes: sprint}, state)
    }
    return adapter.upsertOne(sprint, state);
  }),
  on(SprintActions.updateSprintViaEvent, (state, action) => {
    const sprint = action.payload;
    return adapter.updateOne({ id: sprint.id, changes: sprint }, state);
  }),

  on(SprintActions.updateSprintSuccess, (state, action) => {
    const sprintData = action?.payload?.data[0];
    const updateInProgress = { ...state?.updateInProgress };
    delete updateInProgress[sprintData?.id];
    state = { ...state, updateInProgress };
    if (sprintData) {
      return adapter.updateOne(
        { id: sprintData?.id, changes: sprintData },
        state
      );
    }
    return state;
  }),
);
export interface IProjectRequest {
  title: string
  orgId: string
  requestId?: any
}

export interface IProjectResponse extends IProjectRequest {
  createdAt?: String
  created_by?: String
  updatedAt?: String
  updated_by?: String
  id: string
}

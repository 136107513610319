import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectIdOfActiveSprint, selectSprintList } from '../../store/selectors/sprint.selector';
import { ReplaySubject, takeUntil } from 'rxjs';
import { ISprintResponse } from '../../models/interfaces/sprint.interface';
import { selectActiveOrgId, selectActiveProjectId } from '../../store/reducers';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  createSprint,
  setActiveSprint,
} from '../../store/actions/sprint.actions';
import { MatDialog } from '@angular/material/dialog';
import { SprintCreationDialog } from './sprint-creation-dialog/sprint-creation-dialog';

@Component({
  selector: 'app-sprint-list',
  templateUrl: './sprint-list.component.html',
  styleUrls: ['./sprint-list.component.scss'],
})
export class SprintListComponent implements OnInit {
  public sprintList: ISprintResponse[];
  public activeOrgId: string;
  public activeProjectId: string;
  public activeSprintId: string;
  addSprintForm: FormGroup = this.formBuilder.group({
    title: null,
    startDate: null,
    endDate: null,
  });
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public todayDate: any = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectSprintList), takeUntil(this.destroyed$))
      .subscribe(async (sprintList) => {
        let tempSprintList = [];
        for (let sprint of sprintList) {
          sprint = { ...sprint };
          sprint.start_date = await this.transformDate(sprint.start_date);
          sprint.end_date = await this.transformDate(sprint.end_date);
          tempSprintList.push(sprint);
        }
        this.sprintList = tempSprintList;
      });

    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      });

    this.store
      .pipe(select(selectIdOfActiveSprint), takeUntil(this.destroyed$))
      .subscribe((activeSprintId) => {
        this.activeSprintId = activeSprintId;
      });

    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((activeProjectId) => {
        this.activeProjectId = activeProjectId;
      });

    let todayDate = this.todayDate.toISOString().split('T')[0];
    this.todayDate = todayDate;
    
  }

  public redirectToSprint(projectId: string, sprintId: string) {
    this.store.dispatch(setActiveSprint({ sprintId: sprintId }));
    this.router.navigate([
      `orgs/${this.activeOrgId}/project/${projectId}/sprint/${sprintId}`,
    ]);
  }

  public async transformDate(date: any) {
    if(date) {
      const dateArray = date?.split('T');
      return dateArray[0];
    }    
  }

  saveDetails() {
    let createSprintPayload = this.addSprintForm.value
    if (this.isDateColliding(createSprintPayload)) {
      return;
    }
    createSprintPayload.start_date = createSprintPayload.startDate;
    createSprintPayload.end_date = createSprintPayload.endDate;
    createSprintPayload.project_id = this.activeProjectId;
    delete createSprintPayload.startDate;
    delete createSprintPayload.endDate;
    this.store.dispatch(createSprint({ model: createSprintPayload }));
  }

  private isDateColliding(currentSprint: any) {
    if (new Date(currentSprint.startDate) >= new Date(currentSprint.endDate)) {
      alert('Sprint end date must be after its starting date');
      return true;
    }

    // returns true if even a single existing
    // sprint collide with the new sprint
    return !(this.sprintList.every((sprint: ISprintResponse) => {
      if (
        (new Date(currentSprint.endDate) < new Date(sprint.start_date)) ||
        (new Date(currentSprint.startDate) > new Date(sprint.end_date))
      ) {
        return true;
      }

      alert('A sprint with colliding dates already exist');
      return false;
    }));
  }

  public openSprintCreationDialog() {
    const dialogRef = this.dialog.open(SprintCreationDialog, {
      width: '400px',
      data: {
        sprintList: this.sprintList,
        activeProjectId: this.activeProjectId,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    
    });
  }
}

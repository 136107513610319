import { createFeatureSelector, createSelector } from '@ngrx/store'
import { selectActiveSprintId } from '../reducers'
import * as SprintReducer from '../reducers/sprint.reducer'

export const { selectAll, selectEntities } = SprintReducer.adapter.getSelectors()

export const selectSprintListState = createFeatureSelector<SprintReducer.SprintState>(
  SprintReducer.sprintFeatureKey
)

export const selectSprintList = createSelector(selectSprintListState, selectAll)

export const selectSprintListEntities = createSelector(
  selectSprintListState,
  selectEntities
)

export const selectSprintById = createSelector(
  selectSprintListState,
  selectActiveSprintId,
  (state, sprintId) => {
    return state?.entities[sprintId];
  }
)

export const selectIdOfActiveSprint = createSelector(
  selectSprintListState,
  (state) => {
    console.log("check Id: ", state);
    return state.activeSprintId;
  }
)
import { createAction, props } from '@ngrx/store'
import {  RealTimeResponse } from '../../models'
import { IProjectUserResponse } from '../../models/interfaces/project-user.interface'

export const loadAllProjectUser = createAction(
  '[project_user] Load all Project User',
  props<{ projectId: string }>()
)

export const loadAllProjectUserSuccess = createAction(
  '[project_user] Load all Projects User Success',
  props<{ payload: RealTimeResponse<IProjectUserResponse[]> }>()
)

export const loadAllProjectUserFailure = createAction(
  '[project] Load all Projects Failure',
  props<{ error: any }>()
)

export const createProjectUser = createAction(
    '[project_user] Create a project user',
    props<{ body:any}>()
  )
  
  export const createProjectUserSuccess = createAction(
    '[project_user] Create project user success',
    props<{ payload: RealTimeResponse<any> }>()
  )
  
  export const createProjectUserFailure = createAction(
    '[project_user] Create project user Failure',
    props<{ error: any }>()
  )

  export const deleteProjectUser = createAction(
    '[project_user] Delete project user',
    props<{ projectUserId: string }>()
  )
  
  export const deleteProjectUserSuccess = createAction(
    '[project_user] Delete project user success',
    props<{ payload: RealTimeResponse<any> }>()
  )
  
  export const deleteProjectUserFailure = createAction(
    '[project_user] Delete project user Failure',
    props<{ error: any }>()
  )
<mat-card class="sprint-tasks" (dblclick)="redirectToCard(task.id)">
  <div class="card-title">
    <input
        type="text"
        [(ngModel)]="task.title"
        (keyup)="titleUpdate()"
      />
  </div>
  <mat-form-field appearance="fill">
    <mat-label>Feature</mat-label>
    <mat-select
      [value]="task.parent_id"
      (selectionChange)="onFeatureChange($event)"
    >
      <mat-option *ngFor="let feature of featureList" [value]="feature.id">
        {{ feature.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Sprint</mat-label>
    <mat-select
      [value]="task.sprint_id"
      (selectionChange)="onSprintChange($event)"
    >
      <mat-option *ngFor="let sprint of sprintList" [value]="sprint.id">
        {{ sprint.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Stages</mat-label>
    <mat-select
      [value]="task.stageId"
      (selectionChange)="onStageChange($event)"
    >
      <mat-option *ngFor="let stage of stages" [value]="stage.id">
        {{ stage.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <img
    class="assignee-icon"
    (click)="openAssigneeDialog()"
    src="{{task.assigned_to ? userEntities[task.assigned_to].avatar : '../../../../../assets/icons/dummy-avatar.png'}}"
  />
</mat-card>

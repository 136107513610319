import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, skip, takeUntil, timer } from 'rxjs';
import { loadAllTasks, loadSingleTask } from '../../store/actions/card.actions';
import { loadSingleProject } from '../../store/actions/project.actions';
import { loadAllProjectUser } from '../../store/actions/projectUser.actions';
import { loadUser } from '../../store/actions/user.actions';
import { selectActiveCard, selectCardListEntities } from '../../store/selectors/card.selector';

@Component({
  selector: 'app-card-detail-view-wrapper',
  templateUrl: './card-detail-view-wrapper.component.html',
  styleUrls: ['./card-detail-view-wrapper.component.scss']
})
export class CardDetailViewWrapperComponent implements OnInit {
  public cardId: string;
  public showLoader: boolean = true;
  private projectId: string = '';
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private route: ActivatedRoute, private store: Store) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.cardId = params.get('cardId');
      this.projectId = params.get('projectId');  
      console.log(this.projectId, this.cardId)
      this.store.dispatch(loadUser());
      this.store.dispatch(loadSingleProject({
        projectId: this.projectId
      }));
      this.store.dispatch(loadSingleTask({
        cardId: this.cardId
      }));
    })

    this.store
      .pipe(select(selectActiveCard(this.cardId)), takeUntil(this.destroyed$))
      .subscribe((card: any) => {
        if (card && card?.id) {
          this.showLoader = false;
        }
      })
  }

}

import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-type-property',
  templateUrl: './select-type-property.component.html',
  styleUrls: ['./select-type-property.component.scss']
})
export class SelectTypePropertyComponent implements OnInit {

  @Input() inputData:any;
  @Input() dataValue: any;
  @Output() submitValue = new EventEmitter<any>();
  public inputValue: string;
  constructor() { }

  ngOnInit(): void {
    this.inputValue = this.dataValue;
  }

  public onOptionChange(event) {
    this.inputValue = event.value;
    this.submitValue.emit({
      id: this.inputData.id,
      value: this.inputValue,
    })
  }

}

<div mat-dialog-content>
  <p>Name of the Sprint</p>
  <mat-form-field appearance="fill">
    <input matInput [(ngModel)]="data.title">
  </mat-form-field>
  <p>Start date of the Sprint</p>
  <mat-form-field appearance="fill">
    <input matInput type="date" [(ngModel)]="data.start_date">
  </mat-form-field>
  <p>End Date of the Sprint</p>
  <mat-form-field appearance="fill">
    <input matInput type="date" [(ngModel)]="data.end_date">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Back</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>

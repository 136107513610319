<div class="main-body" class="sprint-board-scrolling">
  <mat-card-header class="sprint-header">
    <mat-card-title>
      <span [innerHTML]="selectedSprint.title"></span>,
    </mat-card-title>
    <mat-card-subtitle class="sprint-subtitle">
      <span class="sprint-date">{{
        selectedSprint.start_date | date: "shortDate"
      }}</span>
      -
      <span class="sprint-date">{{
        selectedSprint.end_date | date: "shortDate"
      }}</span>
      <span>
        <a mat-icon-button title="Edit" (click)="editSprint()">
          <mat-icon>edit</mat-icon>
        </a>
      </span>
    </mat-card-subtitle>
  </mat-card-header>
  <div class="tab-container">
    <ul>
      <li
        [ngClass]="selectedView === 'kanban' ? 'active' : ''"
        (click)="setTabView('kanban')"
      >
        Kanban
      </li>
      <li
        [ngClass]="selectedView === 'table' ? 'active' : ''"
        (click)="setTabView('table')"
      >
        Table
      </li>
    </ul>
  </div>
  <ng-container *ngIf="selectedView === 'kanban'">
    <app-kanban-view-sprint></app-kanban-view-sprint>
  </ng-container>
  <ng-container *ngIf="selectedView === 'table'">
    <app-task-table-view
      [fromComponent]="'sprintList'"
    ></app-task-table-view>
    <button mat-raised-button color="primary" (click)="addNewCard()">
      Add New Card
    </button>
  </ng-container>
</div>

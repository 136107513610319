import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { EMPTY } from 'rxjs'
import { map, catchError, switchMap } from 'rxjs/operators'
import * as ProjectActions from '../actions/project.actions'
import { ProjectService } from '../services/project.service'

@Injectable()
export class ProjectEffects {
  constructor(private actions$: Actions, private projectService: ProjectService) {}

  loadSingleProject$ = createEffect(() => 
    this.actions$.pipe(
      ofType(ProjectActions.loadSingleProject),
      switchMap((payload) => {
        return this.projectService.fetchSingleProject(payload.projectId)
      }),
      map((project) => ({
        type: ProjectActions.loadSingleProjectSuccess.type,
        payload: project
      })),
      catchError(() => EMPTY)
    )
  )

  loadAllProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadAllProjects),
      switchMap((payload) => {
        return this.projectService.fetchAllProjects(payload.orgId)
      }),
      map((allProjects) => ({
        type: ProjectActions.loadAllProjectsSuccess.type,
        payload: allProjects,
      })),
      catchError(() => EMPTY)
    )
  )

  createProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.createProject),
      switchMap((payload) => {
        return this.projectService.createProject(payload.body)
      }),
      map((project) => {
        return {
        type: ProjectActions.createProjectSuccess.type,
        payload: project,
      }}),
      catchError(() => EMPTY)
    )
  )

  udpateProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.updateProject),
      switchMap((payload) => {
        return this.projectService.updateProject(payload.projectId, payload.body)
      }),
      map((project) => {
        return {
        type: ProjectActions.updateProjectSuccess.type,
        payload: project,
      }}),
      catchError(() => EMPTY)
    )
  )
}

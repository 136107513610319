import { Injectable } from '@angular/core'
import { USER_APIS } from '../apis/user.apis'
import {
  RealTimeResponse,
  IProfileResponse,
  IOrgUserResponse,
} from '@app/models'
import { HttpWrapperService } from './http-wrapper.service'

@Injectable()
export class UserService {
  constructor(private httpWrapperService: HttpWrapperService) {}

  public fetchUser(): Promise<RealTimeResponse<IProfileResponse>> {
    const url = USER_APIS.FETCH_USER
    return this.httpWrapperService
      .get(url, {})
      .then((res) => new RealTimeResponse<IProfileResponse>().success(res.body))
      .catch((err) => new RealTimeResponse<IProfileResponse>().error(err.error))
  }

  // update user
  public updateUser(
    userId: string,
    profile: IProfileResponse
  ): Promise<RealTimeResponse<IProfileResponse>> {
    const url = USER_APIS.UPDATE_USER.replace(':userId', String(userId))
    return this.httpWrapperService
      .patch(url, profile, {})
      .then((res) => new RealTimeResponse<IProfileResponse>().success(res.body))
      .catch((err) => new RealTimeResponse<IProfileResponse>().error(err.error))
  }

  // update org user
  public updateOrgUser(
    orgUserId: string,
    profile: IOrgUserResponse
  ): Promise<RealTimeResponse<IProfileResponse>> {
    const url = USER_APIS.UPDATE_ORG_USER.replace(
      ':orgUserId',
      String(orgUserId)
    )
    return this.httpWrapperService
      .patch(url, profile, {})
      .then((res) => new RealTimeResponse<IProfileResponse>().success(res.body))
      .catch((err) => new RealTimeResponse<IProfileResponse>().error(err.error))
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';
import { selectActiveProjectId } from '../../store/reducers';
import { LocalService } from '../../store/services/local.service';
import { ICardResponse } from '../../models/interfaces/card.interface';
import { loadAllTasks, createCard } from '../../store/actions/card.actions';
import { v4 as uuidv4 } from 'uuid';

import {
  selectCardByFeatureId,
  selectCardIds,
  selectCardListEntities,
  selectFeatureByModuleId,
} from '../../store/selectors/card.selector';
import { selectActiveOrgId } from '../../store/reducers';
@Component({
  selector: 'app-all-prd',
  templateUrl: './all-prd.component.html',
  styleUrls: ['./all-prd.component.scss'],
})
export class AllPrdComponent implements OnInit, OnDestroy {
  public activeProjectId: string = '';
  public activeSprintId: any;
  public cardByFeatureIds: any;
  public featuresByModuleIds: any;
  public cardListEntities: { [id: string]: ICardResponse };
  public cardListIds: any[] = [];
  public moduleListIds: any[] = [];
  public featureListIds: any[] = [];
  public activeModuleId: string;
  public activeFeatureId: string;
  public isEditMode = false;
  public activeOrgId: any;
  public activeCardIdForDetailView: string;
  public lastClickedComponent = 'module';
  public sidebarCollapsed: boolean;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private store: Store, private localService: LocalService) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((activeProjectId) => {
        if (activeProjectId) {
          this.activeProjectId = activeProjectId;
        }
      });
      this.localService.sidebarCollapsed$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sidebarCollapsed) => {
        this.sidebarCollapsed = sidebarCollapsed;
      });
    this.localService.toggleSidebarCollapsed(false);
    this.store
      .pipe(select(selectFeatureByModuleId), takeUntil(this.destroyed$))
      .subscribe((featureByModuleIds) => {
        this.featuresByModuleIds = featureByModuleIds;
      });

    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId: string) => {
        this.activeOrgId = activeOrgId;
      });

    this.store
      .pipe(select(selectCardByFeatureId), takeUntil(this.destroyed$))
      .subscribe((cardByFeatureIds) => {
        this.cardByFeatureIds = cardByFeatureIds;
      });

    this.store
      .pipe(select(selectCardIds), takeUntil(this.destroyed$))
      .subscribe((ids) => {
        this.cardListIds = ids;
      });
    this.store
      .pipe(select(selectCardListEntities), takeUntil(this.destroyed$))
      .subscribe((entities) => {
        this.cardListEntities = entities;
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public addNewModule() {
    this.store.dispatch(
      createCard({
        body: {
          title: 'untitled',
          project_id: this.activeProjectId,
          type: 'MODULE',
          requestId: uuidv4(),
        },
      })
    );
  }

  public addNewFeature() {
    this.store.dispatch(
      createCard({
        body: {
          title: 'untitled feature',
          project_id: this.activeProjectId,
          parent_id: this.activeModuleId,
          type: 'FEATURE',
          requestId: uuidv4(),
        },
      })
    );
  }

  public addNewCard() {
    this.store.dispatch(
      createCard({
        body: {
          title: 'untitled task',
          project_id: this.activeProjectId,
          parent_id: this.activeFeatureId,
          type: 'TASK',
          requestId: uuidv4(),
        },
      })
    );
  }

  public openModuleFeatures(moduleId: string) {
    if (this.activeModuleId != moduleId) {
      this.activeModuleId = moduleId;
      this.activeFeatureId = undefined;
      this.activeCardIdForDetailView = moduleId;
    }
    this.lastClickedComponent = 'module';
    if(!this.sidebarCollapsed) {
      this.localService.toggleSidebarCollapsed(true);
    }
  }

  public openCardFeatures(featureId: string) {
    this.activeFeatureId = featureId;
    this.activeCardIdForDetailView = featureId;
    this.lastClickedComponent = 'feature';
    if(!this.sidebarCollapsed) {
      this.localService.toggleSidebarCollapsed(true);
    }
  }

  public goToCard(cardId: string) {
    this.localService.goToCard(cardId, this.activeOrgId, this.activeProjectId);
  }

  public listItemClicked(cardId: string) {
    this.activeCardIdForDetailView = cardId;
    this.lastClickedComponent = 'task';
    if(!this.sidebarCollapsed) {
      this.localService.toggleSidebarCollapsed(true);
    }
  }
}

import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { EMPTY } from 'rxjs'
import { map, catchError, switchMap } from 'rxjs/operators'
import * as CardActions from '../actions/card.actions'
import { CardService } from '../services/card.service'

@Injectable()
export class CardEffects {
  constructor(private actions$: Actions, private cardService: CardService) {}

  loadSingleCard$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CardActions.loadSingleTask),
      switchMap((payload) => {
        return this.cardService.fetchSingleCard(payload.cardId)
      }),
      map((card) => ({
        type: CardActions.loadSingleTaskSuccess.type,
        payload: card
      })),
      catchError(() => EMPTY)
    )
  )

  loadAllCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CardActions.loadAllTasks),
      switchMap((payload) => {
        return this.cardService.fetchAllCards(payload.projectId)
      }),
      map((allCards) => { console.log(allCards, 'all cards effects'); return {
        type: CardActions.loadAllTasksSuccess.type,
        payload: allCards,
      }}),
      catchError(() => EMPTY)
    )
  )

  createCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CardActions.createCard),
      switchMap((payload) => {
        return this.cardService.createCard(payload.body)
      }),
      map((card) => {
        return {
        type: CardActions.createCardSuccess.type,
        payload: card,
      }}),
      catchError(() => EMPTY)
    )
  )

  udpateFeatures$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CardActions.updateCard),
    switchMap((payload) => {
      return this.cardService.updateCard(payload.cardId, payload.body)
    }),
    map((card) => {
      return {
      type: CardActions.updateCardSuccess.type,
      payload: card,
    }}),
    catchError(() => EMPTY)
  )
)
}

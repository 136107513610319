import { Injectable } from '@angular/core';
import { RealTimeResponse, IProjectPropertiesResponse } from '@app/models';
import { PROJECT_PROPERTIES_API } from '../apis/projectProperties';
import { HttpWrapperService } from './http-wrapper.service';

@Injectable()
export class ProjectPropertiesService {
  constructor(private httpWrapperService: HttpWrapperService) {}

  public async fetchAllProjectProperties(
    projectId: string
  ): Promise<RealTimeResponse<IProjectPropertiesResponse[]>> {
    const url = PROJECT_PROPERTIES_API.FETCH_ALL_PROPERTIES.replace(':projectId', String(projectId))
    try {
      const res = await this.httpWrapperService
        .get(url, {})
      return new RealTimeResponse<IProjectPropertiesResponse[]>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<IProjectPropertiesResponse[]>().error(err.error)
    }
  }

  public async createProjectProperties(
    projectId: string,
    isStage: boolean,
    body: any
  ): Promise<RealTimeResponse<any[]>> {
    const url = PROJECT_PROPERTIES_API.CREATE_PROJECT_PROPERTIES.replace(
      ':projectId',
      projectId
    ).replace(':isStage', isStage ? 'true' : 'false');
    try {
      const res = await this.httpWrapperService.post(url, body);
      return new RealTimeResponse<any[]>().success(res.body);
    } catch (err) {
      return new RealTimeResponse<any[]>().error(err.error);
    }
  }

  public async updateProjectProperties(
    propertyId: string,
    projectId: string,
    body: any,
    isMoved: boolean
  ): Promise<RealTimeResponse<any[]>> {
    const url = `${PROJECT_PROPERTIES_API.UPDATE_PROJECT_PROPERTIES}`
      .replace(':propertyId', propertyId)
      .replace(':projectId', projectId)
      .replace(':isMoved', isMoved.toString())
    try {
      const res = await this.httpWrapperService.patch(url, body);
      return new RealTimeResponse<any[]>().success(res.body);
    } catch (err) {
      return new RealTimeResponse<any[]>().error(err.error);
    }
  }

  public async deleteProjectProperties(
    propertyId: string,
    projectId: string
  ): Promise<RealTimeResponse<IProjectPropertiesResponse[]>> {
    const url = PROJECT_PROPERTIES_API.DELETE_PROJECT_PROPERTIES.replace(
      ':propertyId',
      String(propertyId)
    ).replace(':projectId', projectId);
    try {
      const res = await this.httpWrapperService.delete(url, {});
      return new RealTimeResponse<IProjectPropertiesResponse[]>().success(
        res.body
      );
    } catch (err) {
      return new RealTimeResponse<IProjectPropertiesResponse[]>().error(
        err.error
      );
    }
  }
}

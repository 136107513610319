import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { environment } from '@app/src/environments/environment'
import { AppCookieService } from './cookie.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private cookieService: AppCookieService,
    private router: Router
  ) {}
  canActivate(): boolean {
    const token = this.cookieService.get(
      `${environment?.environment}-feathers-jwt`
    )
    if (token) {
      return true
    } else {
      this.router.navigate(['login'])
      return false
    }
  }
}

<ng-container class="main-wrapper">
  <div class="project-list-container">
    <div><h5 class="project-header">Followed Projects</h5></div>
    <ng-container *ngFor="let project of projectList">
      <mat-card class="project-list" (click)="redirectToProject(project.id)">
        <div>
          <mat-card-title>{{ project?.title }}</mat-card-title>
          <mat-card-subtitle style="margin: 0%"
            >Created at
            {{ project?.createdAt | date: "MMM d yyyy" }}</mat-card-subtitle
          >
        </div>
        <div class="f-bold project-btn action-button">
          <span>&#8594;</span>
        </div>
      </mat-card>
    </ng-container>
    <button mat-button (click)="openProjectCreateDialog()">Add Project</button>
  </div>
</ng-container>

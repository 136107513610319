<div class="example-container">
  <h2>Sprint Task</h2>
  <div
    id="sprint-task"
    cdkDropList
    #sprintList="cdkDropList"
    [cdkDropListData]="cardInSprint"
    [cdkDropListConnectedTo]="[productBacklogList]"
    class="example-list"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="example-box"
      *ngFor="let card of cardInSprint"
      cdkDrag
      [id]="card.id"
    >
      <app-card [task]="card"></app-card>
    </div>
  </div>
</div>

<div class="example-container">
  <h2>Product Backlog</h2>
  <div
    id="product-backlog"
    cdkDropList
    #productBacklogList="cdkDropList"
    [cdkDropListData]="cardInProductBacklog"
    [cdkDropListConnectedTo]="[sprintList]"
    class="example-list"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="example-box" *ngFor="let card of cardInProductBacklog" cdkDrag [id]="card.id">
      <app-card [task]="card"></app-card>
    </div>
  </div>
</div>

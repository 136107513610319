<h1 mat-dialog-title>Create Project</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Project Name</mat-label>
    <input matInput [(ngModel)]="data.projectName" placeholder="Project Name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.projectName" cdkFocusInitial>Create</button>
</div>
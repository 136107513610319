import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

  @Input() public imageUrl: string;
  @Input() public size: number = 38;
  @Input() public round: boolean = true;
  @Input() public fitWidth: boolean;
  @Input() public autoHeight: boolean;

  public fallBackDpPath = '../../../assets/fallback-dp/dp1.svg'

  constructor() { }

  ngOnInit(): void {
  }

}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on, Action } from '@ngrx/store'
import * as UserActions from '../actions/user.actions'
import { IProfileResponse } from '@app/models'
import { loadOrg, loadOrgSuccess } from '../actions/org.actions'
import { environment } from '@app/src/environments/environment'

export const userFeatureKey = 'user'

export interface UserState extends EntityState<IProfileResponse> {
  currentUserId: string
  loading: boolean
  isLoaded: boolean
}

export const adapter: EntityAdapter<IProfileResponse> = createEntityAdapter<IProfileResponse>(
  {
    sortComparer: sortByName,
  }
)

export function sortByName(a: IProfileResponse, b: IProfileResponse): number {
  if (!a?.firstName) {
    a = { ...a, firstName: '' }
  }
  if (!b?.firstName) {
    b = { ...b, firstName: '' }
  }
  return a?.firstName.trim().localeCompare(b?.firstName.trim())
}

export const initialState: UserState = adapter.getInitialState({
  currentUserId: null,
  loading: null,
  isLoaded: null,
})

export const userReducer = createReducer(
  initialState,

  on(loadOrg, (state, action) => {
    state = { ...state, loading: true, isLoaded: false }
    return state
  }),

  on(loadOrgSuccess, (state, action) => {
    if (action.payload.data?.users) {
      state = { ...state, loading: false, isLoaded: true }
      const users = action.payload.data?.users
      const tempUsers: any[] = []
      users.forEach((user) => {
        if (user?.avatarKey) {
          const avatarUrl = `${environment.CLOUD_BASE_URL}/cdn-cgi/image/width=100/${user.avatarKey}`
          const avatarUrlHD = `${environment.CLOUD_BASE_URL}/cdn-cgi/image/width=800/${user.avatarKey}`
          user = { ...user, avatarUrl, avatarUrlHD }
        } else {
          user = { ...user, avatarUrl: '', avatarUrlHD: '' }
        }
        if (
          user?.id === state?.currentUserId &&
          state?.entities[state?.currentUserId]?.userSettings
        ) {
          user = {
            ...user,
            userSettings: state?.entities[state?.currentUserId]?.userSettings,
          }
        }
        if (user?.org_user?.status === 'joined' || user?.id === '0') {
          user = {
            ...user,
            firstName: user?.firstName?.trim(),
            lastName: user?.lastName?.trim(),
          }
          tempUsers.push(user)
        }
      })
      return adapter.setAll(tempUsers, state)
    }
    return state
  }),

  on(UserActions.loadUserSuccess, (state, action) => {
    if (action.payload?.data) {
      let user = action.payload?.data
      if (user?.avatarKey) {
        const avatarUrl = `${environment.CLOUD_BASE_URL}/cdn-cgi/image/width=100/${user.avatarKey}`
        const avatarUrlHD = `${environment.CLOUD_BASE_URL}/cdn-cgi/image/width=800/${user.avatarKey}`
        user = { ...user, avatarUrl, avatarUrlHD }
      } else {
        user = { ...user, avatarUrl: '', avatarUrlHD: '' }
      }
      user = {
        ...user,
        firstName: user?.firstName?.trim(),
        lastName: user?.lastName?.trim(),
      }
      state = { ...state, currentUserId: user.id }
      return adapter.upsertOne(user, state)
    }
    return state
  }),

  on(UserActions.updateUser, (state, action) => {
    const userModel = { ...action.model }
    return adapter.updateOne({ id: action.userId, changes: userModel }, state)
  }),

  on(UserActions.updateUserSuccess, (state, action) => {
    const user = action.payload?.data
    if (user) {
      return adapter.updateOne({ id: user.id, changes: user }, state)
    }
    return state
  }),

  on(UserActions.updateOrgUser, (state, action) => {
    const orgUserModel = { ...action.model }
    let model = state?.entities[action.userId]?.org_user
    model = { ...model, ...orgUserModel }
    return adapter.updateOne(
      { id: action.userId, changes: { org_user: model } },
      state
    )
  }),

  on(UserActions.updateOrgUserSuccess, (state, action) => {
    const user = action.payload?.data
    if (user) {
      return adapter.updateOne({ id: user.id, changes: user }, state)
    }
    return state
  }),

  on(UserActions.upsertUserViaEvent, (state, action) => {
    let userModel = { ...action.user }
    if (userModel) {
      if (userModel?.avatarKey) {
        const avatarUrl = `${environment.CLOUD_BASE_URL}/cdn-cgi/image/width=100/${userModel.avatarKey}`
        const avatarUrlHD = `${environment.CLOUD_BASE_URL}/cdn-cgi/image/width=800/${userModel.avatarKey}`
        userModel = { ...userModel, avatarUrl, avatarUrlHD }
      } else {
        userModel = { ...userModel, avatarUrl: '', avatarUrlHD: '' }
      }
      userModel = {
        ...userModel,
        firstName: userModel?.firstName?.trim(),
        lastName: userModel?.lastName?.trim(),
      }
      return adapter.upsertOne(userModel, state)
    }
    return state
  })
)

export function reducer(userState: UserState | undefined, action: Action) {
  return userReducer(userState, action)
}

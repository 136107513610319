import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { EMPTY } from 'rxjs'
import { map, catchError, switchMap } from 'rxjs/operators'
import * as ProjectUserActions from '../actions/projectUser.actions'
import { ProjectUserService } from '../services/project-user.service'

@Injectable()
export class ProjectUserEffects{
    constructor(private actions$: Actions, private projectUserService: ProjectUserService) {}

    loadAllProjectsUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectUserActions.loadAllProjectUser),
      switchMap((payload) => {
        return this.projectUserService.fetchAllProjectUser(payload.projectId)
      }),
      map((allProjectUser) => ({
        type: ProjectUserActions.loadAllProjectUserSuccess.type,
        payload: allProjectUser,
      })),
      catchError(() => EMPTY)
    )
  )

    createProjectProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectUserActions.createProjectUser),
      switchMap((payload) => {
        return this.projectUserService.createProjectUser( payload.body)
      }),
      map((allProperties) => ({
        type: ProjectUserActions.createProjectUserSuccess.type,
        payload: allProperties,
      })),
      catchError(() => EMPTY)
    )
  )

  removeProjectUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectUserActions.deleteProjectUser),
      switchMap((payload) => {
        return this.projectUserService.deleteProjectUser(payload.projectUserId)
      }),
      map((allProperties) => {
        return {
        type: ProjectUserActions.deleteProjectUserSuccess.type,
        payload: allProperties,
      }}),
      catchError(() => EMPTY)
    )
  )
}
<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="app"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'default'">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
</ng-container>

<ng-template #default>
  <div class="app-loader">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>

<ng-template #app>
  <div class="app-loader">
    <img src="{{ ASSETS_PATH }}img/logo.svg" alt="Loading..." />
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgComponent } from './org/org.component';
import { HomeComponent } from './home/home.component';
import { OrgRoutingModule } from './org-routing.module';
import { LoaderComponent } from './loader/loader.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {
  CreateProjectDialog,
  ProjectListComponent,
} from './project-list/project-list.component';
import { SpaceRtcEditorModule } from 'space-rtc-editor';
import { AllPrdComponent } from './all-prd/all-prd.component';
import { CardDetailComponent } from './card-detail/card-detail.component';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import {
  DialogOverviewDialog,
  SprintBoardComponent,
} from './sprint-board/sprint-board.component';
import { SprintListComponent } from './sprint-list/sprint-list.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { SettingsComponent } from './settings/settings.component';
import { MatMenuModule } from '@angular/material/menu';
import { EditPropertyComponent } from './edit-property/edit-property.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { InputTypePropertyComponent } from './card-detail/input-type-property/input-type-property.component';
import { SelectTypePropertyComponent } from './card-detail/select-type-property/select-type-property.component';
import { CardDetailViewWrapperComponent } from './card-detail-view-wrapper/card-detail-view-wrapper.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CardComponent } from './card/card.component';
import { TaskTableViewComponent } from './task-table-view/task-table-view.component';
import { CardAssigneeDialog } from './card-assignee-dialog/card-assignee-dialog.component';
import { DelveService } from '../store/services/delve.service';
import { GrommingSessionComponent } from './gromming-session/gromming-session.component';
import { SearchService } from 'universal-search-engine-angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ProductBacklogComponent } from './product-backlog/product-backlog.component';
import { MatTableModule } from '@angular/material/table';
import { TaskCreationDialog } from './task-creation-dialog/task-creation-dialog.component';
import { SearchTypePropertyComponent } from './card-detail/search-type-property/search-type-property.component';
import { KanbanViewSprintComponent } from './sprint-board/kanban-view-sprint/kanban-view-sprint.component';
import { SprintCreationDialog } from './sprint-list/sprint-creation-dialog/sprint-creation-dialog';
import { MatSortModule } from '@angular/material/sort';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';

@NgModule({
  declarations: [
    OrgComponent,
    HomeComponent,
    LoaderComponent,
    ProfilePictureComponent,
    SidebarComponent,
    ProjectListComponent,
    AllPrdComponent,
    SprintCreationDialog,
    CardDetailComponent,
    CreateProjectDialog,
    SprintBoardComponent,
    SprintListComponent,
    DialogOverviewDialog,
    SettingsComponent,
    EditPropertyComponent,
    InputTypePropertyComponent,
    SelectTypePropertyComponent,
    CardDetailViewWrapperComponent,
    CardComponent,
    TaskTableViewComponent,
    CardAssigneeDialog,
    GrommingSessionComponent,
    ProductBacklogComponent,
    TaskCreationDialog,
    SearchTypePropertyComponent,
    KanbanViewSprintComponent,
  ],
  imports: [
    CommonModule,
    OrgRoutingModule,
    MatListModule,
    MatCardModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    DragDropModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatSelectModule,
    CdkAccordionModule,
    MatSortModule,
    MatAutocompleteModule,
    SpaceRtcEditorModule,
    ScrollingModule,
    TableVirtualScrollModule,
  ],
  providers: [DelveService, SearchService],
})
export class OrgModule {}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectActiveOrgId, selectActiveProjectId } from '../../store/reducers';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { selectSprintList } from '../../store/selectors/sprint.selector';
import { selectProjectPropertiesList } from '../../store/selectors/project-properties.selectors';
import { selectCardListEntities } from '../../store/selectors/card.selector';
import { updateCard } from '../../store/actions/card.actions';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardAssigneeDialog } from '../card-assignee-dialog/card-assignee-dialog.component';
import { IProfileResponse } from '../../models';
import { selectUserEntities } from '../../store/selectors/user.selectors';
import { debounce } from 'lodash';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() task: any;
  public activeOrgId: any;
  public sprintList: any;
  public stages: any;
  public featureList: any;
  public userEntities: any;
  private activeProjectId: string = '';
  public titleUpdate = debounce(this.updateTitle, 700);

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      });

    this.store
      .pipe(select(selectUserEntities), takeUntil(this.destroyed$))
      .subscribe((userEntities: { [id: number]: IProfileResponse }) => {
        this.userEntities = userEntities;
      });

    this.store
      .pipe(select(selectSprintList), takeUntil(this.destroyed$))
      .subscribe(async (sprintList) => {
        let tempSprintList = [];
        for (let sprint of sprintList) {
          sprint = { ...sprint };
          sprint.start_date = await this.transformDate(sprint.start_date);
          sprint.end_date = await this.transformDate(sprint.end_date);
          tempSprintList.push(sprint);
        }
        this.sprintList = tempSprintList;
      });

    this.store
      .pipe(select(selectProjectPropertiesList), takeUntil(this.destroyed$))
      .subscribe((propertiesList) => {
        this.stages = propertiesList
          .filter((property: any) => property?.id?.includes('STAGE') === true)
          .sort(
            (property1: any, property2: any) =>
              property1.position - property2.position
          );
      });

    this.store
      .pipe(select(selectCardListEntities), takeUntil(this.destroyed$))
      .subscribe((entities) => {
        const localFeatureList = [];
        Object.keys(entities).forEach((item: any) => {
          if (entities[item].type === 'FEATURE') {
            localFeatureList.push(entities[item]);
          }
        });
        this.featureList = localFeatureList;
      });
    
      this.store
        .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
        .subscribe((projectId) => {
          if (projectId) {
            this.activeProjectId = projectId;
          }
        });
  }

  public redirectToCard(id: string) {
    const url = this.router.createUrlTree([
      `orgs/${this.activeOrgId}/projectId/${this.activeProjectId}/card/${id}`,
    ]);
    window.open(url.toString(), '_blank');
  }

  public async transformDate(date: any) {
    if (date) {
      const dateArray = date?.split('T');
      return dateArray[0];
    }
  }

  public onSprintChange(event) {
    const body = { ...this.task, sprint_id: event.value };
    this.store.dispatch(updateCard({ cardId: this.task.id, body }));
  }

  public onFeatureChange(event) {
    const body = { ...this.task, parent_id: event.value };
    this.store.dispatch(updateCard({ cardId: this.task.id, body }));
  }

  public onStageChange(event) {
    const body = { ...this.task, stageId: event.value };
    this.store.dispatch(updateCard({ cardId: this.task.id, body }));
  }

  public openAssigneeDialog() {
    this.dialog.open(CardAssigneeDialog, {
      data: this.task,
      width: '450px',
    });
  }

  public updateTitle() {
    const body = { ...this.task };
    this.store.dispatch(updateCard({ cardId: this.task.id, body }));
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllPrdComponent } from './all-prd/all-prd.component';
import { OrgComponent } from './org/org.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { SprintBoardComponent } from './sprint-board/sprint-board.component';
import { SprintListComponent } from './sprint-list/sprint-list.component';
import { SettingsComponent } from './settings/settings.component';
import { CardDetailViewWrapperComponent } from './card-detail-view-wrapper/card-detail-view-wrapper.component';
import { GrommingSessionComponent } from './gromming-session/gromming-session.component';
import { ProductBacklogComponent } from './product-backlog/product-backlog.component';
const ROUTES: Routes = [
  {
    path: '',
    component: OrgComponent,
    children: [
      {
        path: ':orgId',
        component: ProjectListComponent,
      },
      {
        path: ':orgId/project/:projectId',
        component: AllPrdComponent,
      },
      {
        path: ':orgId/settings/:projectId',
        component: SettingsComponent,
      },
      {
        path: ':orgId/project/:projectId/sprint/:sprintId',
        component: SprintBoardComponent,
      },
      {
        path: ':orgId/project/:projectId/sprints',
        component: SprintListComponent,
      },
      {
        path: ':orgId/gromming-session/project/:projectId/sprint/:sprintId',
        component: GrommingSessionComponent,
      },
      {
        path: ':orgId/product-backlog/project/:projectId',
        component: ProductBacklogComponent,
      }
    ],
  },
  {
    path: ':orgId/projectId/:projectId/card/:cardId',
    component: CardDetailViewWrapperComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class OrgRoutingModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppCookieService } from '@app/src/app/store/services/cookie.service';
import { DelveService } from '@app/src/app/store/services/delve.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserId, selectUserEntities } from '@app/src/app/store/selectors/user.selectors';
import {
  combineLatest,
  Observable,
  ReplaySubject,
  take,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { selectProjectUserList } from '@app/src/app/store/selectors/project-user.selectors';
import { selectActiveOrgId } from '@app/src/app/store/reducers';
import { environment } from '@app/src/environments/environment';
import { IProfileResponse } from '@app/src/app/models';

@Component({
  selector: 'app-search-type-property',
  templateUrl: './search-type-property.component.html',
  styleUrls: ['./search-type-property.component.scss']
})
export class SearchTypePropertyComponent implements OnInit {
  public formGroup : FormGroup;
  public response:any;
  public filteredOptions;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public currentUserId: any;
  public projectUsers:any;
  activeOrgId: string;
  public memberValue:any;
  @Input() inputData:any;
  @Input() dataValue: any;
  @Output() submitValue = new EventEmitter<any>();
  public inputValue: string;
  userEntities: { [id: number]: IProfileResponse };

  constructor(private store: Store, private cookieService: AppCookieService,private delveService: DelveService, private fb : FormBuilder) { }

  ngOnInit(): void {
    this.inputValue = this.dataValue;
    this.initForm();

    this.store
      .pipe(select(selectCurrentUserId), takeUntil(this.destroyed$))
      .subscribe((userId) => {
        this.currentUserId = userId;
      });

      this.store
      .pipe(select(selectProjectUserList), takeUntil(this.destroyed$))
      .subscribe((projectUserList) => {
        this.projectUsers=projectUserList;
      });

      this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      })

      this.store
      .pipe(select(selectUserEntities), takeUntil(this.destroyed$))
      .subscribe((userEntities: { [id: number]: IProfileResponse }) => {
        this.userEntities = userEntities;
      });

  }
  public initForm(){
    this.formGroup = this.fb.group({
      'employee' : ['']
    })
    this.formGroup.get('employee').valueChanges
    .pipe(debounceTime(200))
    .subscribe(response => {
      console.log('entered data is ', response);
      if(response && response.length){
        this.getNames(response);
      } else {
        this.filteredOptions = [];
      }
      
    })
  }

  public getNames(value){
    this.delveService.search(value,
      this.currentUserId,
      `${environment.environment}-space`,
      environment.DELVE_API_KEY,
      {
        terms: {
          type: ['U'],
          orgId: [this.activeOrgId],
        },
        must_not: {
          isEnabled: [false],
        },
      }).subscribe(response => {
      this.filteredOptions=response;
    })
  }

  public onOptionChange(event) {
    this.inputValue = event?.source?.id?._source?.userId;
    this.submitValue.emit({
      id: this.inputData.id,
      value: this.inputValue,
    })
  }

}


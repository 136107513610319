import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { EMPTY } from 'rxjs'
import { map, catchError, switchMap } from 'rxjs/operators'
import * as ProjectPropertiesActions from '../actions/projectProperties.actions'
import { ProjectPropertiesService } from '../services/project-properties.service';

@Injectable()
export class ProjectPropertiesEffects {
  constructor(private actions$: Actions, private projectPropertiesService: ProjectPropertiesService) {}

  loadAllProjectsProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectPropertiesActions.loadAllProjectProperties),
      switchMap((payload) => {
        return this.projectPropertiesService.fetchAllProjectProperties(payload.projectId)
      }),
      map((allProjectProperties) => ({
        type: ProjectPropertiesActions.loadAllProjectPropertiesSuccess.type,
        payload: allProjectProperties,
      })),
      catchError(() => EMPTY)
    )
  )


  createProjectProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectPropertiesActions.createProjectProperties),
      switchMap((payload) => {
        return this.projectPropertiesService.createProjectProperties(payload.projectId, payload.isStage, payload.body)
      }),
      map((allProperties) => ({
        type: ProjectPropertiesActions.createProjectPropertiesSuccess.type,
        payload: allProperties,
      })),
      catchError(() => EMPTY)
    )
  )

  updateProjectProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectPropertiesActions.updateProjectProperties),
      switchMap((payload) => {
        return this.projectPropertiesService.updateProjectProperties(payload.propertyId, payload.projectId, payload.body, false)
      }),
      map((allProperties) => {
        return {
        type: ProjectPropertiesActions.updateProjectPropertiesSuccess.type,
        payload: allProperties,
      }}),
      catchError(() => EMPTY)
    )
  )

  updateProjectPropertiesPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectPropertiesActions.updateProjectPropertyPosition),
      switchMap((payload) => {
        return this.projectPropertiesService.updateProjectProperties('move', payload.projectId, payload.body, true)
      }),
      map((allProperties) => {
        return {
        type: ProjectPropertiesActions.loadAllProjectPropertiesSuccess.type,
        payload: allProperties,
      }}),
      catchError(() => EMPTY)
    )
  )

  removeProjectProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectPropertiesActions.deleteProjectProperties),
      switchMap((payload) => {
        return this.projectPropertiesService.deleteProjectProperties(payload.propertyId, payload.projectId)
      }),
      map((allProperties) => {
        return {
        type: ProjectPropertiesActions.deleteProjectPropertiesSuccess.type,
        payload: allProperties,
      }}),
      catchError(() => EMPTY)
    )
  )
}

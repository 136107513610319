import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as UserReducer from '../reducers/user.reducer'

export const { selectAll, selectEntities } = UserReducer.adapter.getSelectors()

export const selectUserListState = createFeatureSelector<UserReducer.UserState>(
  UserReducer.userFeatureKey
)

export const selectUserList = createSelector(selectUserListState, selectAll)

export const selectAllUsers = createSelector(
  selectUserListState,
  selectEntities
  );

export const selectUserEntities = createSelector(
  selectUserListState,
  selectEntities,
  (state) => {
    if (state && state.entities) {
      return state.entities
    }
    return null
  }
)

export const isUsersLoaded = createSelector(
  selectUserListState,
  (state) => state.isLoaded
)

export const selectCurrentUserId = createSelector(
  selectUserListState,
  (state) => {
    if (state && state.currentUserId) {
      return state.currentUserId
    }
    return null
  }
)

export const selectCurrentUser = createSelector(
  selectUserEntities,
  selectCurrentUserId,
  (userEntities, userId) => {
    if (userEntities && userId) {
      return userEntities[userId]
    }
    return null
  }
)

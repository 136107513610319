<ng-container>
  <div class="setting-page-main-container">
    <div class="setting-page-list-option-container">
      <ng-container *ngFor="let option of settingOptions">
        <div class="options-list-item" (click)="changeActiveOption(option)">
          <div class="">{{ option }}</div>
        </div>
      </ng-container>
    </div>

    <div class="setting-container" *ngIf="activeOption === 'General'">
      <div class="heading">
        Rename the Product/Project
        </div>
        <form class="rename-project" [formGroup]="renameProjectForm" (ngSubmit)="renameProject(activeProjectId)">
        <mat-form-field class="field" appearance="none">
        <input
          matInput
          class="input-update-project"
          type="text"
          placeholder="Add Name"
          formControlName="title"
        />
        </mat-form-field>
        <button mat-raised-button color="primary" class="add-button">Done</button>
        </form>
    </div>

    <div class="setting-container" *ngIf="activeOption === 'Members'">
      <h3 class="heading">Members</h3>
      <cdk-accordion class="create-property-accordion">
        <cdk-accordion-item
          #accordionItem="cdkAccordionItem"
          class="create-property-accordion-item"
          role="button"
          tabindex="0"
          [attr.id]="'accordion'"
          [attr.aria-expanded]="true"
          [attr.aria-controls]="'accordion-body-'"
        >
          <div class="create-property-item-header">
            <button
              class="add-property-button"
              mat-raised-button
              color="primary"
              (click)="accordionItem.toggle(); matAccordion.closeAll()"
            >
              Add
            </button>
          </div>
          <div
            class="create-property-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
          >
           
            <form [formGroup]="formGroup">
              <mat-form-field>
                <mat-label>
                  Members
                </mat-label>
                <input 
                formControlName="employee"
                 matInput type="text" 
                 name="memberValue"
                [(ngModel)]="memberValue"
                matInput
                class="member-value"
                placeholder="Members"
                [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="createNewProjectUser(memberValue)">
                  <mat-option *ngFor="let item of filteredOptions" [value]="item">
        
                    {{item?._source.title}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
          <mat-nav-list>
            <mat-list-item class="member-name"  *ngFor="let projectUser of projectUsers">
              <a matLine href="...">{{ users?.[projectUser?.userId]?.firstName+ " " + users?.[projectUser?.userId]?.lastName }}</a>
              <button mat-icon-button (click)="deleteProjectUser(projectUser?.id)">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-list-item>
          </mat-nav-list>
        </cdk-accordion-item>
      </cdk-accordion>

      <mat-accordion #matAccordion="matAccordion">
        <div>
          <div
            class="project-properties-box"
            *ngFor="let property of properties"
            cdkDrag
          ></div>
        </div>
      </mat-accordion>
    </div>

    <div
      class="setting-container"
      *ngIf="properties && activeOption === 'Properties'"
    >
      <h3 class="heading">Properties</h3>
      <cdk-accordion class="create-property-accordion">
        <cdk-accordion-item
          #accordionItem="cdkAccordionItem"
          class="create-property-accordion-item"
          role="button"
          tabindex="0"
          [attr.id]="'accordion'"
          [attr.aria-expanded]="true"
          [attr.aria-controls]="'accordion-body-'"
        >
          <div class="create-property-item-header">
            <button
              class="add-property-button"
              mat-raised-button
              color="primary"
              (click)="
                accordionItem.toggle();
                clearInitalSelectionValues();
                matAccordion.closeAll()
              "
            >
              Add
            </button>
          </div>
          <div
            class="create-property-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
          >
            <form class="create-property--container">
              <mat-form-field
                appearance="outline"
                class="properties-form-fields"
              >
                <mat-label>Title</mat-label>
                <input
                  matInput
                  type="text"
                  name="title"
                  placeholder="Title"
                  [(ngModel)]="title"
                />
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                class="properties-form-fields"
              >
                <mat-label>Type</mat-label>
                <mat-select name="type" [(ngModel)]="type">
                  <mat-option value="text">Text</mat-option>
                  <mat-option value="number">Number</mat-option>
                  <mat-option value="people">People</mat-option>
                  <mat-option value="select">Select</mat-option>
                </mat-select>
              </mat-form-field>
              <button
                mat-raised-button
                color="primary"
                (click)="createNewProperty(); accordionItem.toggle()"
              >
                Add
              </button>
            </form>

            <div *ngIf="type === 'select'">
              Add Option
              <form class="create-property-list-container">
                <mat-form-field appearance="outline">
                  <mat-label>Option Label</mat-label>
                  <input
                    name="optionValue"
                    [(ngModel)]="optionValue"
                    matInput
                    class="option-value"
                    placeholder="Label"
                  />
                </mat-form-field>
                <button (click)="addOptionToList()">Add</button>
                <mat-nav-list>
                  <mat-list-item *ngFor="let option of optionList">
                    <a matLine href="...">{{ option }}</a>
                    <button mat-icon-button (click)="removeOption(option)">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-list-item>
                </mat-nav-list>
              </form>
            </div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>

      <mat-accordion #matAccordion="matAccordion">
        <div
          cdkDropList
          class="project-properties-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="project-properties-box"
            *ngFor="let property of properties"
            cdkDrag
          >
            <mat-expansion-panel>
              <mat-expansion-panel-header
                (click)="panelHeaderClicked(property); accordionItem.close()"
              >
                <mat-panel-title class="panel-title">
                  {{ property.title }}
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <form class="project-properties-container">
                <mat-form-field
                  appearance="outline"
                  class="properties-form-fields"
                >
                  <mat-label>Title</mat-label>
                  <input
                    matInput
                    type="text"
                    name="title"
                    placeholder="Title"
                    [(ngModel)]="title"
                  />
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  class="properties-form-fields"
                >
                  <mat-label>Type</mat-label>
                  <mat-select name="type" [(ngModel)]="type">
                    <mat-option value="text">Text</mat-option>
                    <mat-option value="number">Number</mat-option>
                    <mat-option value="people">People</mat-option>
                    <mat-option value="select">Select</mat-option>
                  </mat-select>
                </mat-form-field>

                <button
                  mat-raised-button
                  color="primary"
                  (click)="formSubmit(property.id, false)"
                  class="property-form-buttons"
                >
                  Update
                </button>
                <button
                  mat-raised-button
                  color="warn"
                  (click)="deleteProperty(property.id)"
                  class="property-form-buttons"
                >
                  Delete Property
                </button>
                <mat-icon
                  *ngIf="isVisible"
                  (click)="formSubmit(property.id, true)"
                  class="visibility-eye"
                  >visibility</mat-icon
                >
                <mat-icon
                  *ngIf="!isVisible"
                  (click)="formSubmit(property.id, true)"
                  class="visibility-eye"
                  >visibility_off</mat-icon
                >
              </form>
              <div *ngIf="type === 'select'">
                Add Option
                <form class="project-properties-container">
                  <mat-form-field appearance="outline">
                    <mat-label>Option Label</mat-label>
                    <input
                      name="optionValue"
                      [(ngModel)]="optionValue"
                      matInput
                      placeholder="Label"
                    />
                  </mat-form-field>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="addOptionToList()"
                  >
                    Add
                  </button>
                  <mat-nav-list>
                    <mat-list-item *ngFor="let option of optionList">
                      <a matLine href="...">{{ option }}</a>
                      <button mat-icon-button (click)="removeOption(option)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-list-item>
                  </mat-nav-list>
                </form>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </mat-accordion>
    </div>
    <div class="setting-container" *ngIf="activeOption === 'Stages'">
      <h3 class="heading">Stages</h3>
      <cdk-accordion class="project-properties-accordion">
        <cdk-accordion-item
          #accordionItem="cdkAccordionItem"
          class="project-properties-accordion-item"
          role="button"
          tabindex="0"
          [attr.id]="'accordion'"
          [attr.aria-expanded]="true"
          [attr.aria-controls]="'accordion-body-'"
        >
          <div class="project-properties-accordion-item-header">
            <button
              class="add-property-button"
              mat-raised-button
              color="primary"
              (click)="
                accordionItem.toggle();
                clearInitalSelectionValues();
                matAccordion.closeAll()
              "
            >
              Add
            </button>
          </div>
          <div
            class="project-properties-accordion-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
          >
            <form class="project-properties-container">
              <mat-form-field
                appearance="outline"
                class="properties-form-fields"
              >
                <mat-label>Title</mat-label>
                <input
                  matInput
                  type="text"
                  name="title"
                  placeholder="Title"
                  [(ngModel)]="title"
                />
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                class="properties-form-fields"
              >
                <mat-label>Type</mat-label>
                <mat-select name="type" [(ngModel)]="type">
                  <mat-option value="close">Close</mat-option>
                  <mat-option value="open">Open</mat-option>
                </mat-select>
              </mat-form-field>

              <button
                mat-raised-button
                color="primary"
                (click)="createNewProperty(); accordionItem.toggle()"
              >
                Add
              </button>
            </form>

            <div *ngIf="type === 'select'">
              Add Option
              <form class="project-properties-container">
                <mat-form-field appearance="outline">
                  <mat-label>Option Label</mat-label>
                  <input
                    name="optionValue"
                    [(ngModel)]="optionValue"
                    matInput
                    placeholder="Label"
                  />
                </mat-form-field>
                <button (click)="addOptionToList()">Add</button>
                <mat-nav-list>
                  <mat-list-item *ngFor="let option of optionList">
                    <a matLine href="...">{{ option }}</a>
                    <button mat-icon-button (click)="removeOption(option)">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-list-item>
                </mat-nav-list>
              </form>
            </div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>

      <mat-accordion #matAccordion="matAccordion">
        <div
          cdkDropList
          class="project-properties-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="project-properties-box"
            *ngFor="let property of stages"
            cdkDrag
          >
            <mat-expansion-panel>
              <mat-expansion-panel-header
                (click)="panelHeaderClicked(property); accordionItem.close()"
              >
                <mat-panel-title> {{ property.title }} </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <form class="project-properties-container">
                <mat-form-field
                  appearance="outline"
                  class="properties-form-fields"
                >
                  <mat-label>Title</mat-label>
                  <input
                    matInput
                    type="text"
                    name="title"
                    placeholder="Title"
                    [(ngModel)]="title"
                  />
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  class="properties-form-fields"
                >
                  <mat-label>Type</mat-label>
                  <mat-select name="type" [(ngModel)]="type">
                    <mat-option value="close">Close</mat-option>
                    <mat-option value="open">Open</mat-option>
                  </mat-select>
                </mat-form-field>

                <button
                  mat-raised-button
                  color="primary"
                  (click)="formSubmit(property.id, false)"
                  class="property-form-buttons"
                >
                  Update
                </button>
                <button
                  mat-raised-button
                  color="warn"
                  (click)="deleteProperty(property.id)"
                  class="property-form-buttons"
                >
                  Delete Property
                </button>
                <mat-icon
                  *ngIf="isVisible"
                  (click)="formSubmit(property.id, true)"
                  >visibility</mat-icon
                >
                <mat-icon
                  *ngIf="!isVisible"
                  (click)="formSubmit(property.id, true)"
                  >visibility_off</mat-icon
                >
              </form>
              <div *ngIf="type === 'select'">
                Add Option
                <form class="project-properties-container">
                  <mat-form-field appearance="outline">
                    <mat-label>Option Label</mat-label>
                    <input
                      name="optionValue"
                      [(ngModel)]="optionValue"
                      matInput
                      placeholder="Label"
                    />
                  </mat-form-field>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="addOptionToList()"
                  >
                    Add
                  </button>
                  <mat-nav-list>
                    <mat-list-item *ngFor="let option of optionList">
                      <a matLine href="...">{{ option }}</a>
                      <button mat-icon-button (click)="removeOption(option)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-list-item>
                  </mat-nav-list>
                </form>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </mat-accordion>
    </div>
  </div>
</ng-container>

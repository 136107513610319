import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';
import { IProjectResponse } from '../../models';
import { createProject } from '../../store/actions/project.actions';
import { selectActiveOrgId } from '../../store/reducers';
import { selectProjectList } from '../../store/selectors/project.selectors';
import { v4 as uuidv4 } from 'uuid';
import { loadAllProjectProperties } from '../../store/actions/projectProperties.actions';
import { setActiveSprint } from '../../store/actions/sprint.actions';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit, OnDestroy {
  public projectList: IProjectResponse[];
  public activeOrgId: string;
  public projectName: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectProjectList), takeUntil(this.destroyed$))
      .subscribe((projectList) => {
        this.projectList = projectList;
      });

    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public redirectToProject(projectId: string) {
    this.store.dispatch(loadAllProjectProperties({ projectId }));
    this.store.dispatch(setActiveSprint({ sprintId: null }));
    this.router.navigate([`orgs/${this.activeOrgId}/project/${projectId}`]);
  }

  public openProjectCreateDialog() {
    const dialogRef = this.dialog.open(CreateProjectDialog, {
      data: {
        projectName: this.projectName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.length > 0) {
        this.store.dispatch(
          createProject({
            body: {
              title: result,
              orgId: this.activeOrgId,
              requestId: uuidv4(),
            },
          })
        );
      }
    });
  }
}

@Component({
  selector: 'create-project-dialog',
  templateUrl: 'create-project-dialog.html',
})
export class CreateProjectDialog {
  constructor(
    public dialogRef: MatDialogRef<CreateProjectDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';
import { RealTimeResponse, IProjectPropertiesResponse } from '@app/models';
import { IProjectUserResponse } from '../../models/interfaces/project-user.interface';
import { PROJECT_USER_API } from '../apis/projectUser';
import { HttpWrapperService } from './http-wrapper.service';

@Injectable()
export class ProjectUserService {
    constructor(private httpWrapperService: HttpWrapperService) {}

    public async fetchAllProjectUser(
      projectId: string
    ): Promise<RealTimeResponse<IProjectUserResponse[]>> {
      const url = PROJECT_USER_API.FETCH_ALL_PROJECT_USER.replace(':project_id', String(projectId))
      try {
        const res = await this.httpWrapperService
          .get(url, {})
        return new RealTimeResponse<IProjectUserResponse[]>().success(res.body)
      } catch (err) {
        return new RealTimeResponse<IProjectUserResponse[]>().error(err.error)
      }
    }

    public async createProjectUser(
        body:any
      ): Promise<RealTimeResponse<any[]>> {
        const url = PROJECT_USER_API.CREATE_PROJECT_USER
        try {
          const res = await this.httpWrapperService.post(url,body);
          return new RealTimeResponse<any[]>().success(res.body);
        } catch (err) {
          return new RealTimeResponse<any[]>().error(err.error);
        }
      }

      public async deleteProjectUser(
        projectUserId: string
      ): Promise<RealTimeResponse<IProjectPropertiesResponse[]>> {
        const url = PROJECT_USER_API.DELETE_PROJECT_USER.replace(':projectUserId', projectUserId);
        try {
          const res = await this.httpWrapperService.delete(url, {});
          return new RealTimeResponse<IProjectPropertiesResponse[]>().success(
            res.body
          );
        } catch (err) {
          return new RealTimeResponse<IProjectPropertiesResponse[]>().error(
            err.error
          );
        }
      }
}
import { sprintBaseUrl } from './baseURL.apis'

const projectPropertiesURL = `${sprintBaseUrl}/project-properties`;

export const PROJECT_PROPERTIES_API = {
  FETCH_ALL_PROPERTIES: `${projectPropertiesURL}?projectId=:projectId`,
  CREATE_PROJECT_PROPERTIES: `${projectPropertiesURL}?projectId=:projectId&isStage=:isStage`,
  UPDATE_PROJECT_PROPERTIES: `${projectPropertiesURL}/:propertyId/?projectId=:projectId&isMoved=:isMoved`,
  DELETE_PROJECT_PROPERTIES: `${projectPropertiesURL}/:propertyId/?projectId=:projectId`,
}

import { IOrgResponse } from '.'
export interface IUserPreference {
  chat?: {
    cmdEnter?: boolean
    sidebarWidth?: number
  }
}

export interface IUserSettings {
  chat?: {
    compactSidebar?: boolean
  }
}

export interface IProfileResponse {
  id: string
  email?: string
  firstName: string
  orgs?: IOrgResponse[]
  lastName?: string
  avatar?: string
  avatarKey?: string
  avatarUrl?: string
  avatarUrlHD?: string
  mobileNumber?: string
  createdAt?: Date | string
  updatedAt?: Date | string
  org_user?: IOrgUserResponse
  userSettings?: IUserSettings
  hasJoined?: {
    chat?: boolean
    okr?: boolean
    email?: boolean
  }
}
export interface IOrgUserResponse {
  createdAt?: Date | string
  id: string
  invitedBy?: string
  isEnabled?: boolean
  jobRole?: string
  orgId?: string
  role?: number
  status?: string
  updatedAt?: Date | string
  userId?: string
  userStatus?: any
  customFieldValues?: any
}

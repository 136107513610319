import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ICardResponse } from '../../models/interfaces/card.interface';

import * as CardActions from '../actions/card.actions';

export const cardFeatureKey = 'card';

export interface CardState extends EntityState<any> {
  loading: boolean;
  isLoaded: boolean;
  newCard: ICardResponse | null
}

export const adapter: EntityAdapter<any> =
  createEntityAdapter<any>({
    sortComparer: sortByName,
  });

export function sortByName(a: any, b: any): number {
  return a?.title.localeCompare(b?.title);
}

export const initialState: CardState = adapter.getInitialState({
  loading: null,
  isLoaded: null,
  newCard: null
});

export const cardReducer = createReducer(
  initialState,

  on(CardActions.loadSingleTask, (state) => {
    state = { ...state, loading: true, isLoaded: false };
    return state;
  }),

  on(CardActions.loadSingleTaskSuccess, (state, action) => {
    state = { ...state, loading: false, isLoaded: true };
    const card = action.payload.data;
    return adapter.addOne(card, state);
  }),

  on(CardActions.loadAllTasks, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(CardActions.loadAllTasksSuccess, (state, action) => {
    const cards = action.payload.data;
    // if(cards?.length > 0) {
    // }
    state = {
      ...state,
      loading: false,
      isLoaded: true,
    }
    return adapter.setAll(cards, state);
    return state;
  }),

  on(CardActions.loadAllTasks, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(CardActions.loadAllTasksSuccess, (state, action) => {
    const cards = action.payload.data;
    if(cards?.length > 0) {
      
      state = {
        ...state,
        loading: false,
        isLoaded: true,
      }
      return adapter.setAll(cards, state);
    }
    return state;
  }),

  on(CardActions.createCard, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    const data = {
      ...action.body,
      id: action.body.requestId,
    }
    delete data.requestId;
    return adapter.addOne(data, state);
  }),

  on(CardActions.createCardSuccess, (state, action) => {
   state = {
     ...state,
     newCard: action.payload.data
   }
   return adapter.updateOne({id: action.payload.data.requestId, changes: action.payload.data}, state);
 }),

 on(CardActions.updateCard, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(CardActions.updateCardSuccess, (state, action) => {
    state = {
      ...state,
    }
    return adapter.updateOne({id: action.payload.data.id, changes: action.payload.data}, state);
  }),

  on(CardActions.upsertCardViaEvent, (state, action) => {
    const card = action.payload;
    if(state.entities[card?.requestId]) {
      return adapter.updateOne({id: card?.requestId, changes: card}, state)
    }
    return adapter.upsertOne(card, state);
  }),

  on(CardActions.updateCardViaEvent, (state, action) => {
    const card = action.payload;
    return adapter.updateOne({ id: card.id, changes: card }, state);
  }),

  on(CardActions.resetNewCard, (state) => {
    return {
      ...state,
      newCard: null
    }
  })
);
 
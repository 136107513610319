import { Injectable } from '@angular/core'
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { environment } from '@app/src/environments/environment'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { AuthService } from './auth.service'
import { AppCookieService } from './cookie.service'

@Injectable()
export class HttpWrapperService implements HttpInterceptor {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private cookieService: AppCookieService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          //signout user since not authenticated
          this.authService.signOut()
        }
        return throwError(() => error.statusText)
      })
    )
  }
  public get = (
    url: string,
    params?: any,
    options?: any,
    skipAuthorization = false
  ): Promise<any> => {
    options = this.prepareOptions(options, skipAuthorization)
    options.params = params
    return this.http.get(url, options).toPromise()
  }
  public post = (url: string, body: any, options?: any): Promise<any> => {
    options = this.prepareOptions(options)
    return this.http.post(url, body, options).toPromise()
  }
  public put = (
    url: string,
    body: any,
    options?: any,
    skipAuthorization = false
  ): Promise<any> => {
    options = this.prepareOptions(options, skipAuthorization)
    return this.http.put(url, body, options).toPromise()
  }
  public delete = (url: string, params?: any, options?: any): Promise<any> => {
    options = this.prepareOptions(options)
    options.search = this.objectToParams(params)
    return this.http.delete(url, options).toPromise()
  }
  public patch = (url: string, body: any, options?: any): Promise<any> => {
    options = this.prepareOptions(options)
    return this.http.patch(url, body, options).toPromise()
  }
  private prepareOptions(options: any, skipAuthorization?: boolean): any {
    options = options || {}
    if (!options.headers) {
      options.headers = {} as any
    }
    if (!options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json'
    }
    const authToken = this.cookieService.get(
      `${environment?.environment}-feathers-jwt`
    )
    if (!options.headers.Authorization && authToken && !skipAuthorization) {
      options.headers.Authorization = authToken
    }
    options.headers = new HttpHeaders(options.headers)
    if (!options.observe) {
      options.observe = 'response'
    }
    options.responseType = 'json'
    return options
  }

  private isPrimitive(value: any) {
    return (
      value == null ||
      (typeof value !== 'function' && typeof value !== 'object')
    )
  }
  private objectToParams(object: any = {}) {
    return Object.keys(object)
      .map((value) => {
        const objectValue = this.isPrimitive(object[value])
          ? object[value]
          : JSON.stringify(object[value])
        return `${value}=${objectValue}`
      })
      .join('&')
  }
}

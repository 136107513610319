import { Injectable } from '@angular/core'
import { environment } from '@app/src/environments/environment'
import { Subject } from 'rxjs'
import { AppCookieService } from './cookie.service'
import { FeathersService } from './feathers.service'
import * as localforage from 'localforage'

@Injectable()
export class AuthService {
  public signOutEmitter$ = new Subject<boolean>()

  constructor(
    private feathersService: FeathersService,
    private cookieService: AppCookieService
  ) {}

  public signOut() {
    this.signOutEmitter$.next(true)
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          console.log('registrations to unregister', registrations)
          for (const registration of registrations) {
            registration.unregister()
          }
        })
        .catch(function (err) {
          console.log('Service Worker registration failed: ', err)
        })
    }
    this.feathersService.logout()
    this.cookieService.remove(`${environment?.environment}-feathers-jwt`)
    localforage
      .clear()
      .then(function () {
        // Run this code once the database has been entirely deleted.
        console.log('Database is now empty.')
      })
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err)
      })
    window.localStorage.clear()
    setTimeout(() => {
      // window.open(`${environment.AUTH_UI_URL}?action=signout`, '_self')
    }, 2000)
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PropertyData, PropertyTypes } from '../settings/settings.component';
@Component({
  selector: 'app-edit-property',
  templateUrl: './edit-property.component.html',
  styleUrls: ['./edit-property.component.scss'],
})
export class EditPropertyComponent implements OnInit {
  public properties = null;

  constructor(
    public dialogRef: MatDialogRef<EditPropertyComponent>,
    @Inject(MAT_DIALOG_DATA) public property: PropertyData
  ) {}

  ngOnInit(): void {
    this.properties = Object.keys(PropertyTypes);
  }
  closeDialog() {
    this.dialogRef.close(this.property);
  }
  updateName(event) {
    this.property.title = event.target.value;
  }
  updateType({ value }) {
    this.property.type = value;
  }
}

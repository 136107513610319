import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-type-property',
  templateUrl: './input-type-property.component.html',
  styleUrls: ['./input-type-property.component.scss']
})
export class InputTypePropertyComponent implements OnInit {

  @Input() inputData:any;
  @Input() dataValue: any;
  @Output() submitValue = new EventEmitter<any>();
  public inputValue: string="";
  constructor() { }

  ngOnInit(): void {
    console.log(this.inputData, this.dataValue);
    this.inputValue = this.dataValue;
  }

  public onBlur() {
    const value = this.inputData.type === 'number' ? (isNaN(Number(this.dataValue)) ? null : Number(this.dataValue)) : this.dataValue;
    this.submitValue.emit({
      id: this.inputData.id,
      value: value,
    })
  }
}

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, ReplaySubject, takeUntil, catchError, of } from 'rxjs';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { text } from 'stream/consumers';
import { EditPropertyComponent } from '../edit-property/edit-property.component';
import { v4 as uuidv4 } from 'uuid';
import { selectActiveOrgId, selectActiveProjectId } from '../../store/reducers';
import { selectProjectList } from '../../store/selectors/project.selectors';
import {
  createProjectProperties,
  deleteProjectProperties,
  loadAllProjectProperties,
  updateProjectProperties,
  updateProjectPropertyPosition,
  createProjectMember,
} from '../../store/actions/projectProperties.actions';
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
} from '@angular/cdk/drag-drop';
import { selectProjectPropertiesList } from '../../store/selectors/project-properties.selectors';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { DelveService } from './../../store/services/delve.service';
import {
  selectAllUsers,
  selectCurrentUserId,
} from '../../store/selectors/user.selectors';
import { environment } from '@app/src/environments/environment';
import {
  createProjectUser,
  deleteProjectUser,
  loadAllProjectUser,
} from '../../store/actions/projectUser.actions';
import { selectProjectUserList } from '../../store/selectors/project-user.selectors';
import { updateProject } from '../../store/actions/project.actions';

export interface PropertyData {
  id?: string;
  title: string;
  visible: boolean;
  type: string;
}

export enum PropertyTypes {
  TEXT = 'text',
  NUMBER = 'number',
  PEOPLE = 'people',
  DATE = 'date',
  PHONE = 'phone',
  LINK = 'link',
  EMAIL = 'email',
  TEAM = 'team',
}
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public activeProjectId: string;
  public properties: any;
  public projectUsers: any;
  public members: any;
  public users: any;
  panelOpenState = false;
  public optionValue: any = '';
  public memberValue: any = '';
  public memberName: any = '';
  public memberId: any = '';
  public title: any = '';
  public type: any = '';
  public position: number = 0;
  public isVisible: boolean = false;
  public stages: any;
  public optionList: string[] = [];
  public memberList: string[] = [];
  public activeOption = 'Properties';
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public settingOptions = ['General', 'Members', 'Properties', 'Stages'];
  public searchText = '';
  public searchedChannelAndUserList: any[];
  public openChannelButtons: any[];
  public suggestedChannelsList: any[];
  public resultItem: any;
  public addMemberFormControl = new FormControl();
  public userEntities: string[] = ['One', 'Two', 'Three'];
  public filteredUserEntities: Observable<string[]>;
  public activeOrgId: any;
  public currentUserId: any;
  public filteredOptions;
  public formGroup: FormGroup;
  renameProjectForm: FormGroup = this.formBuilder.group({
    title: null,
  });
  @ViewChild('username', { static: true })
  myusername: string = '';
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private store: Store,
    private delveService: DelveService,
    private fb: FormBuilder,
    private usernameElement: ElementRef
  ) {
    this.usernameElement = usernameElement;
  }

  ngOnInit(): void {
    this.initForm();

    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((activeProjectId) => {
        this.activeProjectId = activeProjectId;
        this.store.dispatch(
          loadAllProjectProperties({ projectId: activeProjectId })
        );
      });

    this.store
      .pipe(select(selectCurrentUserId), takeUntil(this.destroyed$))
      .subscribe((userId) => {
        this.currentUserId = userId;
      });

    this.store.dispatch(
      loadAllProjectUser({ projectId: this.activeProjectId })
    );
    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId: string) => {
        this.activeOrgId = activeOrgId;
      });
    this.store
      .pipe(select(selectProjectPropertiesList), takeUntil(this.destroyed$))
      .subscribe((propertiesList) => {
        this.properties = propertiesList
          .filter((property: any) => property?.id?.includes('STAGE') === false)
          .sort(
            (property1: any, property2: any) =>
              property1.position - property2.position
          );

        this.stages = propertiesList
          .filter((property: any) => property?.id?.includes('STAGE') === true)
          .sort(
            (property1: any, property2: any) =>
              property1.position - property2.position
          );
      });

    this.store
      .pipe(select(selectProjectUserList), takeUntil(this.destroyed$))
      .subscribe((projectUserList) => {
        this.projectUsers = projectUserList;
      });
    this.store
      .pipe(select(selectAllUsers), takeUntil(this.destroyed$))
      .subscribe((user: any) => {
        this.users = user;
      });
  }

  public editProperty(property: PropertyData) {
    this.openDialog(property);
  }
  public toggleVisibility(id: string) {
    this.store.dispatch(
      updateProjectProperties({
        propertyId: id,
        projectId: this.activeProjectId,
        body: {
          isVisible: !(this.activeOption === 'Stages'
            ? this.stages[id].isVisible
            : this.properties[id].isVisible),
        },
      })
    );
  }

  public search(value) {
    this.delveService
      .search(
        value,
        this.currentUserId,
        `${environment.environment}-space`,
        environment.DELVE_API_KEY,
        {
          terms: {
            type: ['U'],
            orgId: [this.activeOrgId],
          },
          must_not: {
            isEnabled: [false],
          },
        }
      )
      .pipe(
        map((res: any) => {
          console.log(res, 'response in delve service');
        }),
        catchError((err) => {
          console.log('error', err);
          return of([]);
        })
      );
    return ['One', 'Two', 'Three'];
  }

  public addProperty() {
    this.openDialog({ title: '', type: PropertyTypes.TEXT });
  }

  public addStage() {
    this.openDialog({ title: '', type: PropertyTypes.TEXT, isStage: true });
  }
  openDialog(data) {
    const dialogRef = this.dialog.open(EditPropertyComponent, {
      width: '35%',
      height: '35%',
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.store.dispatch(
        createProjectProperties({
          projectId: this.activeProjectId,
          isStage: this.activeOption === 'Stages' ? true : false,
          body: {
            title: result?.title,
            type: result?.type,
          },
        })
      );
    });
  }

  public changeActiveOption(option: string) {
    this.activeOption = option;
  }

  public drop(event: any) {
    if (this.activeOption === 'Stages') {
      moveItemInArray(this.stages, event.previousIndex, event.currentIndex);
    } else {
      moveItemInArray(this.properties, event.previousIndex, event.currentIndex);
    }
    const body = {};

    const properties = this.properties.map((item: any, index: number) => ({
      ...item,
      position: index,
    }));
    const stages = this.stages.map((item: any, index: number) => ({
      ...item,
      position: index,
    }));
    [...properties, ...stages].forEach((item: any) => {
      const id = item.id;
      delete item.id;
      body[id] = item;
    });
    this.store.dispatch(
      updateProjectPropertyPosition({
        projectId: this.activeProjectId,
        body,
      })
    );
  }

  public deleteProperty(propertyId) {
    this.store.dispatch(
      deleteProjectProperties({
        propertyId: propertyId,
        projectId: this.activeProjectId,
      })
    );
  }

  public deleteProjectUser(projectUserId) {
    this.store.dispatch(
      deleteProjectUser({
        projectUserId: projectUserId,
      })
    );
  }

  public createNewProperty() {
    const body = {
      title: this.title,
      type: this.type,
      isVisible: true,
      position: this.properties.length,
      options: this.type === 'select' ? this.optionList : [],
    };
    this.store.dispatch(
      createProjectProperties({
        projectId: this.activeProjectId,
        isStage: this.activeOption === 'Stages' ? true : false,
        body,
      })
    );
    this.clearInitalSelectionValues();
  }

  public createNewProjectUser(value) {
    let text = value._id;
    const Id = text.split('_');
    const id = Id[1];

    const body = {
      project_id: this.activeProjectId,
      userId: id,
    };
    this.store.dispatch(
      createProjectUser({
        body,
      })
    );
    this.clearInitalSelectionValues();
  }

  public initForm() {
    this.formGroup = this.fb.group({
      employee: [''],
    });
    this.formGroup
      .get('employee')
      .valueChanges.pipe(debounceTime(200))
      .subscribe((response) => {
        if (response && response.length) {
          this.getNames(response);
        } else {
          this.filteredOptions = [];
        }
      });
  }

  public getNames(value) {
    this.delveService
      .search(
        value,
        this.currentUserId,
        `${environment.environment}-space`,
        environment.DELVE_API_KEY,
        {
          terms: {
            type: ['U'],
            orgId: [this.activeOrgId],
          },
          must_not: {
            isEnabled: [false],
          },
        }
      )
      .subscribe((response) => {
        this.filteredOptions = response;
      });
  }

  public formSubmit(propertyId, changeVisibility) {
    this.store.dispatch(
      updateProjectProperties({
        propertyId: propertyId,
        projectId: this.activeProjectId,
        body: {
          title: this.title,
          type: this.type,
          isVisible: changeVisibility ? !this.isVisible : this.isVisible,
          position: this.position,
          options: this.type === 'select' ? this.optionList : [],
        },
      })
    );
    this.clearInitalSelectionValues();
  }

  public clearInitalSelectionValues() {
    this.title = '';
    this.type = '';
    this.position = 0;
    this.isVisible = true;
    this.optionValue = '';
    this.memberValue = '';
    this.memberName = '';
    this.optionList = [];
    this.memberList = [];
  }

  public panelHeaderClicked(property) {
    this.title = property.title;
    this.type = property.type;
    this.isVisible = property.isVisible;
    this.position = property.position;
    if (property.options) {
      this.optionList = property.options;
    }
  }

  public addOptionToList() {
    this.optionList = [...this.optionList, this.optionValue];
    this.optionValue = '';
  }

  public addMemberToList(value) {
    this.memberValue = value._source.title;

    this.memberList = [...this.memberList, this.memberValue];

    this.memberValue = '';
  }

  public removeOption(option) {
    this.optionList = this.optionList.filter((item: string) => item !== option);
  }

  public removeMember(member) {
    this.memberList = this.memberList.filter((item: string) => item !== member);
  }

  public renameProject(projectId: any) {
    let renameProjectPayload = this.renameProjectForm.value;
    this.store.dispatch(
      updateProject({
        projectId: projectId,
        body: renameProjectPayload,
      })
    );
  }
}

<h1 mat-dialog-title>Create Task</h1>
<div mat-dialog-content>
  <form [formGroup]="newTaskForm" (ngSubmit)="submitFormData()">
    <mat-form-field appearance="fill">
      <mat-label>Task Name</mat-label>
      <input matInput formControlName="taskName" required />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Module</mat-label>
      <mat-select formControlName="module">
       <mat-option *ngFor="let module of moduleList" value="{{module.id}}">{{module.title}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Feature</mat-label>
      <mat-select formControlName="feature">
        <mat-option *ngFor="let feature of featureByModuleWise()" value="{{feature.id}}">{{feature.title}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Sprint</mat-label>
      <mat-select formControlName="sprint">
        <mat-option *ngFor="let sprint of sprintList" value="{{sprint.id}}">{{sprint.title}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary">Create Task</button>
  </form>
</div>

<h1 mat-dialog-title>Assignee</h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Title</mat-label>
    <input
      matInput
      type="text"
      name="title"
      placeholder="Title"
      (keyup)="inputChange($event)"
    />
  </mat-form-field>
  <mat-nav-list>
    <mat-list-item *ngFor="let user of filteredUser">
      <div class="dialog-title-container">
        <img
          class="assignee-icon"
          src="{{
            userEntities[user?._source?.userId]?.avatar ||
            '../../../../../assets/icons/dummy-avatar.png'
          }}"
        />
        <span>{{ user._source.title }} </span>
      </div>
      <button
        *ngIf="currentTaskData.assigned_to !== user._source.userId"
        class="assign-remove-button"
        mat-raised-button
        color="primary"
        (click)="onTaskAssigne(user._source.userId)"
      >
        Assign
      </button>
      <button
        *ngIf="currentTaskData.assigned_to === user._source.userId"
        class="assign-remove-button"
        mat-raised-button
        color="warn"
        (click)="onAssigneRemove()"
      >
        Remove
      </button>
    </mat-list-item>
  </mat-nav-list>
  <button
    mat-raised-button
    class="remove-assignee-button"
    (click)="onAssigneRemove()"
    color="warn"
  >
    Remove Assignee
  </button>
</div>

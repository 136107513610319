import { createAction, props } from "@ngrx/store"
import { ISprintResponse } from "../../models/interfaces/sprint.interface"
import { RealTimeResponse } from '../../models'


export const upsertSprintViaEvent = createAction(
    '[sprint] Upsert sprint via event',
    props<{ payload: ISprintResponse }>()
)

export const updateSprintViaEvent = createAction(
    '[sprint] Update sprint via event',
    props<{ payload: ISprintResponse }>()
)
      
export const loadAllSprints = createAction(
  '[sprint] Load all Sprints',
  props<{ projectId: string }>()
)

export const loadAllSprintsSuccess = createAction(
  '[sprint] Load all Sprints Success',
  props<{ payload: RealTimeResponse<ISprintResponse[]> }>()
)

export const loadAllSprintsFailure = createAction(
  '[sprint] Load all Sprints Faliure',
  props<{ error: any }>()
)

export const loadSprintById = createAction(
  '[sprint] Load a Sprint By ID',
  props<{ sprintId: string }>()
)

export const loadSprintByIdSuccess = createAction(
  '[sprint] Load a Sprint By ID Success',
  props<{ payload: RealTimeResponse<ISprintResponse> }>()
)

export const loadSprintByIdFailure = createAction(
  '[sprint] Load a Sprint By ID Faliure',
  props<{ error: any }>()
)

export const createSprint = createAction(
  '[sprint] Create a Sprint',
  props<{ model: ISprintResponse }>()
)

export const createSprintSuccess = createAction(
  '[sprint] Create a Sprint Success',
  props<{ payload: RealTimeResponse<ISprintResponse[]> }>()
)

export const createSprintFailure = createAction(
  '[sprint] Create a Sprint Faliure',
  props<{ error: any }>()
)

export const updateSprint = createAction(
  '[sprint] Update a Sprint',
  props<{ id: string; model: ISprintResponse }>()
)

export const updateSprintSuccess = createAction(
  '[sprint] Update a Sprint Success',
  props<{ payload: RealTimeResponse<ISprintResponse[]> }>()
)

export const updateSprintFailure = createAction(
  '[sprint] Update a Sprint Faliure',
  props<{ error: any }>()
)

export const setActiveSprint = createAction(
  '[sprint] Set active sprint',
  props<{ sprintId: string }>()
)

export const setActiveSprintSuccess = createAction(
  '[sprint] Set active sprint Success',
  props<{ payload: RealTimeResponse<ISprintResponse> }>()
)

export const setActiveSprintFailure = createAction(
  '[sprint] Set active sprint Faliure',
  props<{ error: any }>()
)
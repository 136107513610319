import { BrowserModule } from '@angular/platform-browser';
import { NgModule, SecurityContext } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpWrapperService } from './store/services/http-wrapper.service';
import { reducers, metaReducers } from './store/reducers';
import { UserService } from './store/services/user.service';
import { OrgService } from './store/services/org.service';
import { UserEffects } from './store/effects/user.effects';
import { OrgEffects } from './store/effects/org.effects';
import { ProjectPropertiesEffects } from './store/effects/project-properties.effects';
import { userReducer } from './store/reducers/user.reducer';
import { orgReducer } from './store/reducers/org.reducer';
import { FeathersService } from './store/services/feathers.service';
import { AuthService } from './store/services/auth.service';
import { LoginModule } from './+login/login.module';
import { HydrationEffects } from './hydration-store/hydration.effects';
import { OrgModule } from './+org/org.module';
import { AppCookieService } from './store/services/cookie.service';
import { AuthGuardService } from './store/services/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectEffects } from './store/effects/project.effects';
import { ProjectService } from './store/services/project.service';
import { ProjectPropertiesService } from './store/services/project-properties.service';
import {
  projectFeatureKey,
  projectReducer,
} from './store/reducers/project.reducer';
import { CardEffects } from './store/effects/card.effects';
import { cardFeatureKey, cardReducer } from './store/reducers/card.reducer';
import { projectPropertiesFeatureKey, projectPropertiesReducer } from './store/reducers/project-properties.reducer';
import { CardService } from './store/services/card.service';
import { MatSliderModule } from '@angular/material/slider';
import { SprintService } from './store/services/sprint.service';
import { SprintEffects } from './store/effects/sprint.effects';
import { sprintFeatureKey, sprintReducer } from './store/reducers/sprint.reducer';
import { DelveService } from './../app/store/services/delve.service';
import { SearchService } from 'universal-search-engine-angular';
import {ProjectUserService} from './../app/store/services/project-user.service'
import {ProjectUserEffects} from './store/effects/project-user.effects'
import { projectUserFeatureKey, projectUserReducer } from './store/reducers/project-user.reducer';
import { ApiModule } from 'universal-search-engine-angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ApiModule,
    HttpClientModule,
    OrgModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'chat development',
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([HydrationEffects]),
    EffectsModule.forFeature([
      UserEffects,
      OrgEffects,
      ProjectEffects,
      CardEffects,
      SprintEffects,
      ProjectPropertiesEffects,
      ProjectUserEffects
    ]),
    StoreModule.forFeature('user', userReducer),
    StoreModule.forFeature('org', orgReducer),
    StoreModule.forFeature(projectFeatureKey, projectReducer),
    StoreModule.forFeature(cardFeatureKey, cardReducer),
    StoreModule.forFeature(sprintFeatureKey, sprintReducer),
    StoreModule.forFeature(projectPropertiesFeatureKey, projectPropertiesReducer),
    StoreModule.forFeature(projectUserFeatureKey, projectUserReducer),
    LoginModule,
    LoginModule,
    BrowserAnimationsModule,
    MatSliderModule,
  ],
  providers: [
    HttpWrapperService,
    UserService,
    OrgService,
    FeathersService,
    AuthService,
    AppCookieService,
    AuthGuardService,
    ProjectService,
    CardService,
    DelveService,
    SearchService,
    SprintService,
    ProjectUserService,
    ProjectPropertiesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpWrapperService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

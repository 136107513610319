<div class="kanban-container" cdkDropListGroup>
  <div class="stage-container" *ngFor="let stage of stages">
    <h2>{{ stage.title }}</h2>
    <div
      id="{{ stage.id }}"
      cdkDropList
      [cdkDropListData]="stageWiseListOfCards[stage.id]"
      class="kanban-view-list"
      cdkDropListSortingDisabled
      (cdkDropListDropped)="drop($event)"
    >
      <div
        class="kanban-view-box"
        *ngFor="let card of stageWiseListOfCards[stage.id]"
        id="{{ card.id }}"
        cdkDrag
        (dblclick)="redirectToCard(card.id)"
      >
        <div class="card-title">
          <input
            value="{{ card.title }}"
            #title
            class="card-title"
            type="text"
            (keyup)="updateTitle(card, title.value)"
          />
        </div>
        <div class="card-detail-container">
          <div>{{ cardListEntities[card?.parent_id]?.title }}</div>
          <img
            class="assignee-icon"
            (click)="openAssigneeDialog(card)"
            src="{{
              userEntities[card.assigned_to]
                ? userEntities[card.assigned_to].avatar
                : '../../../../../assets/icons/dummy-avatar.png'
            }}"
          />
        </div>
      </div>
    </div>
  </div>
</div>

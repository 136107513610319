import { createAction, props } from '@ngrx/store'
import { RealTimeResponse, IOrgResponse } from '@app/models'

export const loadOrg = createAction(
  '[orgs] Load org',
  props<{ orgId: string }>()
)

export const loadOrgSuccess = createAction(
  '[orgs] Load org Success',
  props<{ payload: RealTimeResponse<IOrgResponse> }>()
)

export const loadOrgFailure = createAction(
  '[orgs] Load user orgs Failure',
  props<{ error: any }>()
)

export const loadUserOrgs = createAction('[orgs] Load user orgs')

export const loadUserOrgsSuccess = createAction(
  '[orgs] Load user orgs Success',
  props<{ payload: RealTimeResponse<any> }>()
)

export const loadUserOrgsFailure = createAction(
  '[orgs] Load user orgs Failure',
  props<{ error: any }>()
)

export const updateOrgViaEvent = createAction(
  '[orgs] Update org via Event',
  props<{ org: IOrgResponse }>()
)
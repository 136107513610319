import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as ProjectUserReducer from '../reducers/project-user.reducer'

export const { selectAll, selectEntities } = ProjectUserReducer.adapter.getSelectors()

export const selectProjectUserListState = createFeatureSelector<ProjectUserReducer.ProjectUserState>(
    ProjectUserReducer.projectUserFeatureKey
)

export const selectProjectUserList = createSelector(selectProjectUserListState, selectAll)

export const selectProjectIds = createSelector(
  selectProjectUserListState,
  (state) => state.ids
)

export const selectProjectListEntities = createSelector(
  selectProjectUserListState,
  selectEntities
)
import { Injectable } from '@angular/core';
import { RealTimeResponse } from '../../models';
import {
  ISprintRequest,
  ISprintResponse,
} from '../../models/interfaces/sprint.interface';
import { SPRINT_APIS } from '../apis/sprint.apis';
import { HttpWrapperService } from './http-wrapper.service';

@Injectable()
export class SprintService {
  constructor(private httpWrapperService: HttpWrapperService) {}

  public async fetchAllSprints(
    projectId: string
  ): Promise<RealTimeResponse<ISprintResponse[]>> {
    const url = SPRINT_APIS.FETCH_ALL_SPRINTS.replace(
      ':projectId',
      String(projectId)
    );
    try {
      const res = await this.httpWrapperService.get(url, {});
      return new RealTimeResponse<ISprintResponse[]>().success(res.body);
    } catch (err) {
      return new RealTimeResponse<ISprintResponse[]>().error(err.error);
    }
  }

  public async fetchASprintById(
    sprintId: string
  ): Promise<RealTimeResponse<ISprintResponse[]>> {
    const url = SPRINT_APIS.FETCH_A_SPRINT.replace(
      ':sprintId',
      String(sprintId)
    );
    try {
      const res = await this.httpWrapperService.get(url, {});
      return new RealTimeResponse<ISprintResponse[]>().success(res.body);
    } catch (err) {
      return new RealTimeResponse<ISprintResponse[]>().error(err.error);
    }
  }

  public async createASprint(
    model: ISprintRequest
  ): Promise<RealTimeResponse<ISprintResponse[]>> {
    const url = SPRINT_APIS.CREATE_A_SPRINT;
    try {
      const res = await this.httpWrapperService.post(url, model);
      return new RealTimeResponse<ISprintResponse[]>().success(res.body);
    } catch (err) {
      return new RealTimeResponse<ISprintResponse[]>().error(err.error);
    }
  }

  public async updateASprint(
    sprintId: string,
    model: ISprintRequest
  ): Promise<RealTimeResponse<ISprintResponse[]>> {
    const url = SPRINT_APIS.FETCH_A_SPRINT.replace(
      ':sprintId',
      String(sprintId)
    );
    try {
     const res = await this.httpWrapperService.patch(url, model);
      return new RealTimeResponse<ISprintResponse[]>().success(res.body);
    } catch (err) {
      return new RealTimeResponse<ISprintResponse[]>().error(err.error);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { selectCardListEntities } from '../../store/selectors/card.selector';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';
import { selectActiveSprintId } from '../../store/reducers';
import { updateCard } from '../../store/actions/card.actions';

@Component({
  selector: 'app-gromming-session',
  templateUrl: './gromming-session.component.html',
  styleUrls: ['./gromming-session.component.scss'],
})
export class GrommingSessionComponent implements OnInit {
  constructor(private store: Store) {}
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public cardListEntities: any;
  public cardInSprint: any = [];
  public cardInProductBacklog: any = [];
  public activeSprintId: any;
  public allCards: any;

  ngOnInit(): void {
    this.store
      .pipe(select(selectActiveSprintId), takeUntil(this.destroyed$))
      .subscribe((activeSprintId) => {
        this.activeSprintId = activeSprintId;
      });
    this.store
      .pipe(select(selectCardListEntities), takeUntil(this.destroyed$))
      .subscribe((entities) => {
        this.allCards = entities;
        const itemInSprint = [];
        const itemInProductBacklog = [];
        Object.keys(entities).forEach((key: any) => {
          if (entities[key].type === 'TASK') {
            if (entities[key].sprint_id === this.activeSprintId) {
              itemInSprint.push(entities[key]);
            } else if (entities[key].sprint_id === null) {
              itemInProductBacklog.push(entities[key]);
            }
          }
        });
        this.cardInSprint = itemInSprint;
        this.cardInProductBacklog = itemInProductBacklog;
      });
  }

  public onSprintChange(taskId: string, sprintId: string) {
    console.log('sprint_id', sprintId);
    const body = { ...this.allCards[taskId], sprint_id: sprintId };
    this.store.dispatch(updateCard({ cardId: taskId, body }));
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      if (event.container.id.localeCompare('sprint-task') === 0) {
        this.onSprintChange(
          event.item.element.nativeElement.id,
          this.activeSprintId
        );
      } else {
        this.onSprintChange(event.item.element.nativeElement.id, null);
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}

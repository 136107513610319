import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as ProjectPropertiesReducer from '../reducers/project-properties.reducer'

export const { selectAll, selectEntities } = ProjectPropertiesReducer.adapter.getSelectors()

export const selectProjectPropertiesListState = createFeatureSelector<ProjectPropertiesReducer.ProjectPropertiesState>(
  ProjectPropertiesReducer.projectPropertiesFeatureKey
)

export const selectProjectPropertiesList = createSelector(selectProjectPropertiesListState, selectAll)

export const selectProjectIds = createSelector(
  selectProjectPropertiesListState,
  (state) => state.ids
)

export const selectProjectListEntities = createSelector(
  selectProjectPropertiesListState,
  selectEntities
)
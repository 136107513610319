import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, ReplaySubject, take, takeUntil, takeWhile } from "rxjs";
import { IOrgResponse, IProfileResponse, IProjectResponse } from "../../models";
import {
  loadOrg,
  loadUserOrgs,
  updateOrgViaEvent,
} from "../../store/actions/org.actions";
import { loadUser, upsertUserViaEvent } from "../../store/actions/user.actions";
import { selectActiveOrg, selectActiveOrgId, selectActiveProjectId } from "../../store/reducers";
import {
  isUsersLoaded,
  selectCurrentUser,
  selectUserEntities,
} from "../../store/selectors/user.selectors";
import { AuthService } from "../../store/services/auth.service";
import { AppCookieService } from "../../store/services/cookie.service";
import { FeathersService } from "../../store/services/feathers.service";
import { environment } from '@app/src/environments/environment'
import { Router } from '@angular/router';
import { loadAllProjects, updateProjectViaEvent, upsertProjectViaEvent,upsertProjectUserViaEvent } from "../../store/actions/project.actions";
import { selectProjectList } from "../../store/selectors/project.selectors";
import { IProjectUserResponse } from "../../models/interfaces/project-user.interface";
import { ICardResponse } from "../../models/interfaces/card.interface";
import { loadAllTasks, updateCardViaEvent, upsertCardViaEvent } from "../../store/actions/card.actions";
import { ISprintResponse } from "../../models/interfaces/sprint.interface";
import { updateSprintViaEvent, upsertSprintViaEvent } from "../../store/actions/sprint.actions";
import { loadAllSprints } from "../../store/actions/sprint.actions";

@Component({
  selector: "app-org",
  templateUrl: "./org.component.html",
  styleUrls: ["./org.component.scss"],
})
export class OrgComponent implements OnInit, OnDestroy {
  public currentUser: IProfileResponse;
  public activeOrg: IOrgResponse;
  public userEntities: { [id: number]: IProfileResponse };
  public showLoader: boolean = true;
  public projectList$: Observable<IProjectResponse[]>
  public activeProjectId: string;

  private activeOrgId: string;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private cookieService: AppCookieService,
    private store: Store,
    private feathersService: FeathersService,
    private authService: AuthService,
    private zone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authenticateWebsocket();
    this.initializeFeathersEvents();

    this.store
      .pipe(select(selectCurrentUser), takeUntil(this.destroyed$))
      .subscribe((currentUser: IProfileResponse) => {
        if (
          currentUser &&
          (!this.currentUser || this.currentUser?.id !== currentUser?.id)
        ) {
          this.currentUser = currentUser;
          this.initApiCalls();
        }
        this.currentUser = currentUser;
      });

    this.store
      .pipe(select(selectUserEntities), takeUntil(this.destroyed$))
      .subscribe((userEntities: { [id: number]: IProfileResponse }) => {
        this.userEntities = userEntities;
      });

    this.store
      .pipe(select(selectActiveOrg), takeUntil(this.destroyed$))
      .subscribe((activeOrg: IOrgResponse) => {
        this.activeOrg = activeOrg;
      });

      this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId: string) => {
        this.activeOrgId = activeOrgId;
      });

      this.store
      .pipe(select(isUsersLoaded), takeUntil(this.destroyed$))
      .subscribe((isUsersLoaded: boolean) => {
        this.showLoader = !isUsersLoaded;
      });
    this.projectList$ = this.store.pipe(
      select(selectProjectList),
      takeUntil(this.destroyed$)
    )

    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((projectId) => {
        this.activeProjectId = projectId;
        if (projectId) {
          this.store.dispatch(
            loadAllSprints({ projectId: this.activeProjectId })
          );
          this.store.dispatch(loadAllTasks({ projectId: projectId }));
        }
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initApiCalls() {
    this.store.dispatch(
      loadOrg({
        orgId: this.activeOrgId,
      })
    );
    this.store.dispatch(loadUserOrgs());
    this.store.dispatch(loadAllProjects({ orgId: this.activeOrgId }));
  }

  private authenticateWebsocket() {
    const token = this.cookieService.get(
      `${environment?.environment}-feathers-jwt`
    );

    if (token) {
      this.store
        .pipe(select(selectActiveOrgId), takeWhile((res) => res === undefined || res === null, true))
        .subscribe((activeOrgId) => {
          if (activeOrgId) {
            this.feathersService
              .authentication({
                strategy: 'jwt',
                accessToken: token,
                orgId: activeOrgId,
                product: 'sprint',
                deviceType: 'WEB',
              })
              .then((res) => {
                this.store.dispatch(loadUser())
              })
              .catch((e) => {
                if (
                  e.message ===
                  'sprint is not activated for the current organisation'
                ) {
                  window.open(
                    `${environment.AUTH_UI_URL}/orgs/${activeOrgId}/onboarding?product=sprint`,
                    '_self'
                  )
                } else {
                  this.authService.signOut()
                }
              })
          } else {
            const activeOrgId = window.localStorage.getItem('activeOrgId')
            this.router.navigate(['orgs', activeOrgId])
          }
        })
    } else {
      this.authService.signOut();
    }
  }

  private initializeFeathersEvents() {
    console.log("initializeFeathersEvents");

    // project events
    this.feathersService.service("sprint/project-users").on("created", (projectUser: IProjectUserResponse) => {
      this.zone.run(() => {
        this.store.dispatch(upsertProjectUserViaEvent({ payload: projectUser }));
      });
    });

    this.feathersService.service("sprint/projects").on("patched", (project: IProjectResponse) => {
      this.zone.run(() => {
        this.store.dispatch(updateProjectViaEvent({ payload: project }));
      });
    });

    this.feathersService.service("sprint/projects").on("created", (project: IProjectResponse) => {
      this.zone.run(() => {
        this.store.dispatch(upsertProjectViaEvent({ payload: project }));
      });
    });

    this.feathersService.service("sprint/sprints").on("created", (sprint: ISprintResponse) => {
      this.zone.run(() => {
         this.store.dispatch(upsertSprintViaEvent({ payload: sprint }));
      });
    });

    this.feathersService.service("sprint/sprints").on("patched", (sprint: ISprintResponse) => {
      this.zone.run(() => {
         this.store.dispatch(updateSprintViaEvent({ payload: sprint }));
      })
    });

    this.feathersService.service("sprint/cards").on("created", (card: ICardResponse) => {
      this.zone.run(() => {
        this.store.dispatch(upsertCardViaEvent({ payload: card }));
      });
    });

    this.feathersService.service("sprint/cards").on("patched", (card: ICardResponse) => {
      this.zone.run(() => {
        this.store.dispatch(updateCardViaEvent({ payload: card }));
      })
    });

    this.feathersService.service("orgs").on("patched", (org: any) => {
      this.zone.run(() => {
        // low
        this.store.dispatch(updateOrgViaEvent({ org }));
      });
    });

    this.feathersService.service("orgUser").on("created", (user: any) => {
      this.zone.run(() => {
        if (user?.org_user?.status === "joined") {
          // low
          this.store.dispatch(upsertUserViaEvent({ user }));
        }
      });
    });

    this.feathersService.service("orgUser").on("patched", (user: any) => {
      this.zone.run(() => {
        // low
        if (!user?.org_user?.isEnabled && user?.id === this.currentUser?.id) {
          console.log("current user has been deactivated");
        } else {
          if (
            user?.org_user?.status === "joined" ||
            !user?.org_user?.isEnabled
          ) {
            this.store.dispatch(upsertUserViaEvent({ user }));
          }
        }
      });
    });

    this.feathersService.service("users").on("patched", (user: any) => {
      this.zone.run(() => {
        // low
        this.store.dispatch(upsertUserViaEvent({ user }));
      });
    });
  }
}

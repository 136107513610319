import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';

import { DelveService } from '../../store/services/delve.service';
import { environment } from '@app/src/environments/environment';
import { FormControl, FormControlName, FormGroup } from '@angular/forms';
import { selectSprintList } from '../../store/selectors/sprint.selector';
import { selectCardListEntities } from '../../store/selectors/card.selector';
import { createCard } from '../../store/actions/card.actions';
import { selectActiveOrgId, selectActiveProjectId } from '../../store/reducers';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'task-creation-dialog',
  templateUrl: 'task-creation-dialog.component.html',
  styleUrls: ['./task-creation-dialog.component.scss'],
})
export class TaskCreationDialog {
  public title: any;
  public activeOrgId: any;
  public activeProjectId: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public filteredUser: any = [];
  public userEntities: any;
  public currentUserId: any;
  public currentTaskData: any;
  public sprintList: any = [];
  public featureList: any = [];
  public moduleList: any = [];
  public newTaskForm = new FormGroup({
    taskName: new FormControl(),
    module: new FormControl(),
    feature: new FormControl(),
    sprint: new FormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskCreationDialog>,
    public delveService: DelveService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((activeProjectId) => {
        this.activeProjectId = activeProjectId;
      });

    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId: string) => {
        this.activeOrgId = activeOrgId;
      });

    this.store
      .pipe(select(selectSprintList), takeUntil(this.destroyed$))
      .subscribe(async (sprintList) => {
        let tempSprintList = [];
        for (let sprint of sprintList) {
          sprint = { ...sprint };
          sprint.start_date = await this.transformDate(sprint.start_date);
          sprint.end_date = await this.transformDate(sprint.end_date);
          tempSprintList.push(sprint);
        }
        this.sprintList = tempSprintList;
      });

    this.store
      .pipe(select(selectCardListEntities), takeUntil(this.destroyed$))
      .subscribe((entities) => {
        const localFeatureList = [];
        const localModuleList = [];
        Object.keys(entities).forEach((key: any) => {
          if (entities[key].type === 'FEATURE') {
            localFeatureList.push(entities[key]);
          } else if (entities[key].type === 'MODULE') {
            localModuleList.push(entities[key]);
          }
        });
        this.featureList = localFeatureList;
        this.moduleList = localModuleList;
      });

    console.log(this.moduleList, this.featureList, this.sprintList);
  }

  public async transformDate(date: any) {
    if (date) {
      const dateArray = date?.split('T');
      return dateArray[0];
    }
  }

  public inputChange(event) {
    this.delveService
      .search(
        event.target.value,
        this.currentUserId,
        `${environment.environment}-space`,
        environment.DELVE_API_KEY,
        {
          terms: {
            type: ['U'],
            orgId: [this.activeOrgId],
          },
          must_not: {
            isEnabled: [false],
          },
        }
      )
      .subscribe((response) => {
        this.filteredUser = response;
      });
  }

  public submitFormData() {
    if (this.newTaskForm.status === 'VALID') {
      console.log(this.newTaskForm.value, 'new task form value');
      this.store.dispatch(
        createCard({
          body: {
            title: this.newTaskForm.value.taskName,
            project_id: this.activeProjectId,
            type: 'TASK',
            parent_id: this.newTaskForm.value.feature,
            sprint_id: this.newTaskForm.value.sprint,
            requestId: uuidv4(),
          },
        })
      );
      this.dialogRef.close();
    } 
  }

  public featureByModuleWise() {
    return this.featureList.filter((item: any) => item.parent_id === this.newTaskForm.value.module);
  }
}

import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@app/src/environments/environment'
import { AppCookieService } from '../../store/services/cookie.service'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private cookieService: AppCookieService
  ) {}

  public ngOnInit() {
    const orgId = this.router.routerState.snapshot.root.queryParams.org
    if (!orgId) {
      const activeOrgId = window.localStorage.getItem('activeOrgId')
      const token = this.cookieService.get(
        `${environment?.environment}-feathers-jwt`
      )
      if (activeOrgId && token) {
        this.navigateToOrg(activeOrgId)
      } else {
        this.redirectToSpaceAuth()
      }
    } else {
      const token = this.cookieService.get(
        `${environment?.environment}-feathers-jwt`
      )
      window.localStorage.setItem(
        `${environment?.environment}-feathers-jwt`,
        token
      )
      window.localStorage.setItem('activeOrgId', orgId)
      this.navigateToOrg(orgId)
    }
  }

  private redirectToSpaceAuth() {
    window.open(
      `${environment.AUTH_UI_URL}?redirect_to=${window.location.host}`,
      '_self'
    )
  }

  private navigateToOrg(orgId: string) {
    this.router.navigate(['orgs', orgId])
  }
}

<ng-container>
  <mat-selection-list [multiple]="false" dense class="doc-list" [ngClass]="{'active-module-list': lastClickedComponent === 'module'}">
    <div class="grid">

      <h5 class="title">Modules/Epic</h5>
      <!-- <button mat-button (click)="addNewModule()" 
       color="primary">
        <img
        style="display: inline; height: 20px"
        src="../../assets/img/add_black.svg"
        alt=""
      />
      </button> -->
      <button mat-icon-button (click)="addNewModule()" 
      color="primary">
        <mat-icon>add</mat-icon>
      </button>

    </div>
    <ng-container *ngFor="let moduleId of cardListIds;">
      <mat-list-option *ngIf="cardListEntities[moduleId]?.type === 'MODULE'" (click)="openModuleFeatures(moduleId)" #listItem
        [ngClass]="{ active: moduleId === activeModuleId }" class="doc-list-item">
        <div matLine (dblclick)="goToCard(cardListEntities[moduleId]?.id)"> {{ cardListEntities[moduleId]?.title }}</div>
      </mat-list-option>

    </ng-container>

  </mat-selection-list>

  <mat-selection-list *ngIf="activeModuleId" [multiple]="false" dense class="doc-list" [ngClass]="{'active-feature-list': lastClickedComponent === 'feature'}">
    <div class="grid">

      <h5 class="title">Features/Requirements</h5>
      <button mat-icon-button (click)="addNewFeature()" color="primary">
       <mat-icon>add</mat-icon>
      </button>
    </div>
    <ng-container *ngFor="let featureId of featuresByModuleIds[activeModuleId];"> 
      <mat-list-option (click)="openCardFeatures(featureId)" #listItem
        [ngClass]="{ active: featureId === activeFeatureId }" class="doc-list-item">
        <div matLine (dblclick)="goToCard(cardListEntities[featureId]?.id)">{{cardListEntities[featureId]?.title}}</div>
      </mat-list-option>
    </ng-container>

  </mat-selection-list>

  <mat-selection-list *ngIf="activeModuleId" [multiple]="false" dense class="doc-list" [ngClass]="{'active-task-list': lastClickedComponent === 'task'}">
    <div class="grid">
      <h5 class="title">Tasks</h5>
      <button mat-icon-button (click)="addNewCard()" color="primary">
       <mat-icon>add</mat-icon>
      </button>
    </div>
    <ng-container *ngFor="let cardId of cardByFeatureIds[activeFeatureId];">
      <mat-list-option (click)="listItemClicked(cardListEntities[cardId]?.id)"  #listItem class="doc-list-item">
        <div matLine (dblclick)="goToCard(cardListEntities[cardId]?.id)">{{ cardListEntities[cardId]?.title }}</div>
      </mat-list-option>
    </ng-container>
    
  </mat-selection-list>
  <div class="card-detail-container">
  <app-card-detail *ngIf="activeCardIdForDetailView" [inputCardIdValue]="activeCardIdForDetailView"></app-card-detail>
  </div>
</ng-container>

import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectCardBySprintId } from '../../store/selectors/card.selector';
import { ReplaySubject, takeUntil } from 'rxjs';
import {
  selectActiveOrgId,
  selectActiveProjectId,
  selectActiveSprintId,
} from '../../store/reducers';
import { selectSprintById } from '../../store/selectors/sprint.selector';
import {
  loadSprintById,
  updateSprint,
} from '../../store/actions/sprint.actions';
import { createCard } from '../../store/actions/card.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
export interface DialogData {
  title: string;
  start_date: any;
  end_date: any;
}

@Component({
  selector: 'app-sprint-board',
  templateUrl: './sprint-board.component.html',
  styleUrls: ['./sprint-board.component.scss'],
})
export class SprintBoardComponent implements OnInit {
  public activeOrgId: any;
  public selectedView: string = 'kanban';
  public activeSprintId: any;
  public activeProjectId: string = '';
  public cardBySprintId: any;
  public selectedSprint: any;
  public editSprintModel: any;
  public title: any;
  public start_date: any;
  public end_date: any;
  types = ['Task', 'Feature', 'Module'];
  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dev',
  ];
  updateCard: FormGroup = this.formBuilder.group({
    title: null,
  });

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectCardBySprintId), takeUntil(this.destroyed$))
      .subscribe((cardList) => {
        this.cardBySprintId = [];
        for (let element of cardList) {
          element = { ...element };
          this.cardBySprintId.push(element);
        }
      });

    this.store
      .pipe(select(selectActiveSprintId), takeUntil(this.destroyed$))
      .subscribe((activeSprintId) => {
        this.activeSprintId = activeSprintId;
      });

    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      });

    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((activeProjectId) => {
        if (activeProjectId) {
          this.activeProjectId = activeProjectId;
        }
      });

    this.store
      .pipe(select(selectSprintById), takeUntil(this.destroyed$))
      .subscribe(async (activeSprint) => {
        if (activeSprint) {
          this.selectedSprint = { ...activeSprint };
          this.selectedSprint.start_date = await this.transformDate(
            this.selectedSprint.start_date
          );
          this.selectedSprint.end_date = await this.transformDate(
            this.selectedSprint.end_date
          );
        } else {
          if (this.activeSprintId) {
            this.store.dispatch(
              loadSprintById({ sprintId: this.activeSprintId })
            );
          }
        }
      });
  }

  public async transformDate(date: any) {
    if (date) {
      const dateArray = date.split('T');
      return dateArray[0];
    }
  }

  public addNewCard() {
    this.store.dispatch(
      createCard({
        body: {
          title: 'untitled task',
          project_id: this.activeProjectId,
          sprint_id: this.activeSprintId,
          type: 'TASK',
          requestId: uuidv4(),
        },
      })
    );
  }

  public cardEdited(id: string, data: any, dataType: any) {
    let body = {};
    if (dataType == 'type') {
      data = data.toUpperCase();
      body = {
        type: data,
      };
    }
    //this.store.dispatch(updateCard({ cardId: id, body }));
  }

  public redirectToCard(id: string) {
    this.router.navigate([
      `orgs/${this.activeOrgId}/projectId/${this.activeProjectId}/card/${id}`,
    ]);
  }

  editSprint(): void {
    const dialogRef = this.dialog.open(DialogOverviewDialog, {
      width: '250px',
      data: {
        title: this.title,
        start_date: this.start_date,
        end_date: this.end_date,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          updateSprint({ id: this.activeSprintId, model: result })
        );
      }
    });
  }
  public setTabView(view) {
    this.selectedView = view;
  }
}

@Component({
  selector: 'dialog-overview-dialog',
  templateUrl: 'dialog-overview-dialog.html',
})
export class DialogOverviewDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

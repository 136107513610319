import { SearchService } from 'universal-search-engine-angular'
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class DelveService {
  constructor(private service: SearchService) {}

  search(
    term: string,
    currentUserId: string,
    indexName: string,
    apiKey: string,
    searchParameters?: any
  ) {
    return this.service
      .searchQuery(indexName, term, apiKey, 10, currentUserId, searchParameters)
      .pipe(
        map((response: any) => {
          if (!response?.hits?.hits) {
            return []
          }
          return response.hits.hits
        })
      )
  }
}
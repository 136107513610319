import { createAction, props } from '@ngrx/store'
import { IProjectResponse, RealTimeResponse } from '../../models'
import { ICardResponse } from '../../models/interfaces/card.interface'

export const loadSingleTask = createAction(
    '[cards] Load single card',
    props<{ cardId: string }>()
)

export const loadSingleTaskSuccess = createAction(
    '[cards] Load single card success',
    props<{ payload: RealTimeResponse<ICardResponse> }>()
)

export const loadSingleTaskFailure = createAction(
    '[cards] Load single task failure',
    props<{ error: any }>()
)

export const loadAllTasks = createAction(
    '[cards] Load all Cards',
    props<{ projectId: string }>()
)

export const loadAllTasksSuccess = createAction(
    '[cards] Load all Cards Success',
    props<{ payload: RealTimeResponse<ICardResponse[]> }>()
)

export const loadAllTasksFailure = createAction(
    '[cards] Load all Cards Failure',
    props<{ error: any }>()
)

export const createCard = createAction(
    '[cards] Create a card',
    props<{ body: any }>()
)

export const createCardSuccess = createAction(
    '[cards] Create card success',
    props<{ payload: RealTimeResponse<any> }>()
)

export const createCardFailure = createAction(
    '[cards] Create card Failure',
    props<{ error: any }>()
)

export const updateCard = createAction(
    '[cards] Update a card',
    props<{ cardId: string, body: any }>()
)

export const updateCardSuccess = createAction(
    '[cards] Update card success',
    props<{ payload: RealTimeResponse<any> }>()
)

export const updateCardFailure = createAction(
    '[cards] Update card Failure',
    props<{ error: any }>()
)

export const upsertCardViaEvent = createAction(
    '[cards] Upsert card via event',
    props<{ payload: ICardResponse }>()
)

export const updateCardViaEvent = createAction(
    '[cards] Update card via event',
    props<{ payload: ICardResponse }>()
)

export const resetNewCard = createAction(
    '[cards] Reset new card'
)
<div>
  <mat-form-field appearance="fill">
    <mat-select
      [value]="inputValue"
      (selectionChange)="onOptionChange($event)"
    >
      <mat-option
        [value]="option"
        *ngFor="let option of inputData?.options"
        >{{option}}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';
import { updateCard } from '../../store/actions/card.actions';
import { selectActiveOrgId } from '../../store/reducers';
import {
  selectCurrentUserId,
  selectUserEntities,
} from '../../store/selectors/user.selectors';
import { DelveService } from '../../store/services/delve.service';
import { environment } from '@app/src/environments/environment';
import { IProfileResponse } from '../../models';

@Component({
  selector: 'card-assignee-dialog',
  templateUrl: 'card-assignee-dialog.component.html',
  styleUrls: ['./card-assignee-dialog.component.scss'],
})
export class CardAssigneeDialog {
  public title: any;
  public activeOrgId: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public currentUserId: any;
  public filteredUser: any = [];
  public userEntities: any;
  public currentTaskData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public delveService: DelveService,
    private store: Store
  ) {
    this.currentTaskData = { ...this.data };
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId: string) => {
        this.activeOrgId = activeOrgId;
      });

    this.store
      .pipe(select(selectCurrentUserId), takeUntil(this.destroyed$))
      .subscribe((currentUserId: string) => {
        this.currentUserId = currentUserId;
      });
    this.store
      .pipe(select(selectUserEntities), takeUntil(this.destroyed$))
      .subscribe((userEntities: { [id: number]: IProfileResponse }) => {
        this.userEntities = userEntities;
      });
  }

  public onTaskAssigne(userId: string) {
    this.currentTaskData.assigned_to = userId;
    this.store.dispatch(updateCard({ cardId: this.currentTaskData.id, body: {...this.currentTaskData} }));
  }

  public onAssigneRemove() {
    this.currentTaskData.assigned_to = null;
    this.store.dispatch(updateCard({ cardId: this.currentTaskData.id, body: {...this.currentTaskData} }));
  }

  public inputChange(event) {
    this.delveService
      .search(
        event.target.value,
        this.currentUserId,
        `${environment.environment}-space`,
        environment.DELVE_API_KEY,
        {
          terms: {
            type: ['U'],
            orgId: [this.activeOrgId],
          },
          must_not: {
            isEnabled: [false],
          },
        }
      )
      .subscribe((response) => {
        this.filteredUser = response;
      });
  }
}

import { createFeatureSelector, createSelector, defaultMemoize } from '@ngrx/store'
import * as ProjectReducer from '../reducers/project.reducer'

export const { selectAll, selectEntities } = ProjectReducer.adapter.getSelectors()

export const selectProjectListState = createFeatureSelector<ProjectReducer.ProjectState>(
  ProjectReducer.projectFeatureKey
)

export const selectProjectList = createSelector(selectProjectListState, selectAll)

export const selectProjectIds = createSelector(
  selectProjectListState,
  (state) => state.ids
)

export const selectProjectListEntities = createSelector(
  selectProjectListState,
  selectEntities
)

const _selectActiveProject = (projectId: string) => {
  return createSelector(selectProjectListEntities, (projects: any) => {
    if (projects && projects[projectId]) {
      return projects[projectId];
    }
    return null;
  });
};

export const selectActiveProject = (projectId: string) => 
  defaultMemoize(_selectActiveProject).memoized(projectId);
import { createAction, props } from '@ngrx/store'
import { RealTimeResponse } from '../../models'
import { IProjectUserResponse } from '../../models/interfaces/project-user.interface'
import { IProjectResponse } from '../../models/interfaces/project.interface'

export const loadSingleProject = createAction(
  '[project] Load single project',
  props<{ projectId: string }>()
)

export const loadSingleProjectSuccess = createAction(
  '[project] Load single project success',
  props<{ payload: RealTimeResponse<IProjectResponse> }>()
)

export const loadSingleProjectFailure = createAction(
  '[project] Load single project failure',
  props<{ error: any }>()
)

export const loadAllProjects = createAction(
  '[project] Load all Projects',
  props<{ orgId: string }>()
)

export const loadAllProjectsSuccess = createAction(
  '[project] Load all Projects Success',
  props<{ payload: RealTimeResponse<IProjectUserResponse[]> }>()
)

export const loadAllProjectsFailure = createAction(
  '[project] Load all Projects Failure',
  props<{ error: any }>()
)

export const upsertProjectUserViaEvent = createAction(
  '[project] Upsert project-user via event',
  props<{ payload: IProjectUserResponse }>()
)
export const upsertProjectViaEvent = createAction(
  '[project] Upsert project via event',
  props<{ payload: IProjectResponse }>()
)

export const updateProjectViaEvent = createAction(
  '[project] Update project via event',
  props<{ payload: IProjectResponse }>()
)

export const createProject = createAction(
  '[projects] Create a project',
  props<{ body: any }>()
)

export const createProjectSuccess = createAction(
  '[projects] Create project success',
  props<{ payload: RealTimeResponse<any> }>()
)

export const createProjectFailure = createAction(
  '[projects] Create project Failure',
  props<{ error: any }>()
)

export const updateProject = createAction(
  '[projects] Update a project',
  props<{ projectId: string, body: any }>()
)

export const updateProjectSuccess = createAction(
  '[projects] Update project success',
  props<{ payload: RealTimeResponse<any> }>()
)

export const updateProjectFailure = createAction(
  '[projects] Update project Failure',
  props<{ error: any }>()
)
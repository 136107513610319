import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IProjectPropertiesResponse } from '@app/models';
import * as ProjectPropertiesActions from '../actions/projectProperties.actions';

export const projectPropertiesFeatureKey = 'project_properties';

export interface ProjectPropertiesState
  extends EntityState<IProjectPropertiesResponse> {
  loading: boolean;
  isLoaded: boolean;
}

export const adapter: EntityAdapter<IProjectPropertiesResponse> =
  createEntityAdapter<IProjectPropertiesResponse>({
    sortComparer: sortByName,
  });

export function sortByName(
  a: IProjectPropertiesResponse,
  b: IProjectPropertiesResponse
): number {
  return a?.title.localeCompare(b?.title);
}

export const initialState: ProjectPropertiesState = adapter.getInitialState({
  loading: null,
  isLoaded: null,
});

export const projectPropertiesReducer = createReducer(
  initialState,

  on(ProjectPropertiesActions.loadAllProjectProperties, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(
    ProjectPropertiesActions.loadAllProjectPropertiesSuccess,
    (state, action) => {
      if (action.payload.data) {
        const data = Object.keys(action.payload.data).map((id: string) => ({
          id: id,
          ...action.payload.data[id],
        }));
        return adapter.setAll(data, state);
      }
      return state;
    }
  ),

  on(ProjectPropertiesActions.createProjectProperties, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(
    ProjectPropertiesActions.createProjectPropertiesSuccess,
    (state, action) => {
      return adapter.addOne(action.payload.data, state);
    }
  ),

  on(ProjectPropertiesActions.updateProjectProperties, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(
    ProjectPropertiesActions.updateProjectPropertiesSuccess,
    (state, action) => {
      return adapter.updateOne(
        {
          id: action.payload.data.propertyId,
          changes: action.payload.data.data,
        },
        state
      );
    }
  ),

  on(ProjectPropertiesActions.deleteProjectProperties, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  }),

  on(
    ProjectPropertiesActions.deleteProjectPropertiesSuccess,
    (state, action) => {
      const changedObject: any = {
        ...state.entities[action.payload.data.projectId],
        properties: action?.payload?.data?.properties,
      };
      return adapter.removeOne(action.payload.data.deletedPropertyId, state);
    }
  ),

  on(ProjectPropertiesActions.updateProjectPropertyPosition, (state, action) => {
    if (!state?.ids?.length) {
      state = { ...state, loading: true, isLoaded: false };
    }
    return state;
  })
);

import { Injectable } from '@angular/core'
import {
  RealTimeResponse,
} from '@app/models'
import { ICardResponse } from '../../models/interfaces/card.interface';
import { CARD_APIS } from '../apis/card.apis'
import { HttpWrapperService } from './http-wrapper.service'

@Injectable() 
export class CardService {
  constructor(private httpWrapperService: HttpWrapperService) {}

  public async fetchSingleCard(
    cardId: string,
  ): Promise<RealTimeResponse<ICardResponse>> {
    const url = `${CARD_APIS.BASE_URL}/${cardId}`;
    try {
      const res = await this.httpWrapperService
        .get(url, {});
      return new RealTimeResponse<ICardResponse>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<ICardResponse>().error(err.error)
    }
  }

  public async fetchAllCards(
    projectId: string
  ): Promise<RealTimeResponse<any[]>> {
    const url = CARD_APIS.FETCH_ALL_CARDS.replace(':project_id', String(projectId));
    try {
      const res = await this.httpWrapperService
        .get(url, {})
      return new RealTimeResponse<any[]>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<any[]>().error(err.error)
    }
  }

  public async createCard(
    body: any,
  ): Promise<RealTimeResponse<any[]>> {
    const url = CARD_APIS.BASE_URL;
    try {
      const res = await this.httpWrapperService
        .post(url, body)
      return new RealTimeResponse<any[]>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<any[]>().error(err.error)
    }
  }

  public async updateCard(
    cardId: string,
    body: any,
  ): Promise<RealTimeResponse<any[]>> {
    const url = `${CARD_APIS.BASE_URL}/${cardId}`;
    try {
      const res = await this.httpWrapperService
        .patch(url, body)
      return new RealTimeResponse<any[]>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<any[]>().error(err.error)
    }
  }
}


import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { updateCard } from '@app/src/app/store/actions/card.actions';
import {
  selectActiveOrgId,
  selectActiveProjectId,
} from '@app/src/app/store/reducers';
import {
  selectCardBySprintId,
  selectCardListEntities,
} from '@app/src/app/store/selectors/card.selector';
import { selectProjectPropertiesList } from '@app/src/app/store/selectors/project-properties.selectors';
import { selectUserEntities } from '@app/src/app/store/selectors/user.selectors';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';
import { IProfileResponse } from 'space-rtc-editor/lib/space-rtc-editor.interfaces';
import { CardAssigneeDialog } from '../../card-assignee-dialog/card-assignee-dialog.component';

@Component({
  selector: 'app-kanban-view-sprint',
  templateUrl: './kanban-view-sprint.component.html',
  styleUrls: ['./kanban-view-sprint.component.scss'],
})
export class KanbanViewSprintComponent implements OnInit {
  public stages: any = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public stageWiseListOfCards: any = {};
  public cardListEntities: any = {};
  public activeOrgId: string = '';
  public userEntities: any;
  public activeProjectId: string = '';
  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      });
    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((activeProjectId) => {
        if (activeProjectId) {
          this.activeProjectId = activeProjectId;
        }
      });

    this.store
      .pipe(select(selectUserEntities), takeUntil(this.destroyed$))
      .subscribe((userEntities: { [id: number]: IProfileResponse }) => {
        this.userEntities = userEntities;
      });

    this.store
      .pipe(select(selectProjectPropertiesList), takeUntil(this.destroyed$))
      .subscribe((propertiesList) => {
        this.stages = [
          { id: 'unstaged', title: 'Unstaged' },
          ...propertiesList
            .filter((property: any) => property?.id?.includes('STAGE') === true)
            .sort(
              (property1: any, property2: any) =>
                property1.position - property2.position
            ),
        ];
      });

    this.store
      .pipe(select(selectCardBySprintId), takeUntil(this.destroyed$))
      .subscribe((cardList) => {
        this.stageWiseListOfCards = {};
        cardList.map((card: any) => {
          if (!card.stageId) {
            if (this.stageWiseListOfCards.unstaged) {
              this.stageWiseListOfCards.unstaged.push(card);
            } else {
              this.stageWiseListOfCards['unstaged'] = [card];
            }
          } else {
            if (this.stageWiseListOfCards[card.stageId]) {
              this.stageWiseListOfCards[card.stageId].push(card);
            } else {
              this.stageWiseListOfCards[card.stageId] = [card];
            }
          }
        });
      });

    this.store
      .pipe(select(selectCardListEntities), takeUntil(this.destroyed$))
      .subscribe((entities) => {
        this.cardListEntities = entities;
      });
  }

  public onStageChange(cardId: string, stageId: string) {
    if (cardId && stageId) {
      const body = {
        ...this.cardListEntities[cardId],
        stageId: stageId === 'unstaged' ? null : stageId,
      };
      this.store.dispatch(updateCard({ cardId, body }));
    }
  }

  public updateTitle(element, title) {
    const body = { ...this.cardListEntities[element.id], title: title};
    this.store.dispatch(updateCard({ cardId: element.id, body }));
  }

  public redirectToCard(id: string) {
    const url = this.router.createUrlTree([
      `orgs/${this.activeOrgId}/projectId/${this.activeProjectId}/card/${id}`,
    ]);
    window.open(url.toString(), '_blank');
  }
  public openAssigneeDialog(task) {
    this.dialog.open(CardAssigneeDialog, {
      data: task,
      width: '450px',
    });
  }

  public drop(event) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data || [],
        event.previousIndex,
        event.currentIndex
      );
      this.onStageChange(
        event.item.element.nativeElement.id,
        event.container.id
      );
    }
  }
}

<mat-form-field appearance="standard">
  <mat-label>Search</mat-label>
  <input
    id="global_filter"
    matInput
    (keyup)="applyFilter($event)"
    placeholder="Ex. ium"
    #input
  />
</mat-form-field>
<cdk-virtual-scroll-viewport
  tvsItemSize="100"
  bufferMultiplier="bufferMultiplier*10"
  class="virtual-scroll-table-viewport"
  headerEnabled="false"
>
  <table
    mat-table
    *ngIf="!isDragabble"
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    matSort
    (matSortChange)="announceSortChange($event)"
  >
    <ng-container matColumnDef="title">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by Title"
      >
        Title
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="card-title">
          <input
            class="card-title"
            type="text"
            [(ngModel)]="element.title"
            (keyup)="titleUpdate(element)"
          />
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="parent_id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by Feature"
      >
        Feature
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill">
          <mat-label>Feature</mat-label>
          <mat-select
            [value]="element.parent_id"
            (selectionChange)="onFeatureChange($event, element.id)"
          >
            <mat-option
              *ngFor="let feature of featureList"
              [value]="feature.id"
            >
              {{ feature.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="stageId">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by Stage"
      >
        Stage
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill">
          <mat-label>Stages</mat-label>
          <mat-select
            [value]="element.stageId"
            (selectionChange)="onStageChange($event, element.id)"
          >
            <mat-option [value]="null"> Unstage </mat-option>
            <mat-option *ngFor="let stage of stagesList" [value]="stage.id">
              {{ stage.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="sprint_id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by Sprint"
      >
        Sprint
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill">
          <mat-label>Sprint</mat-label>
          <mat-select
            [value]="element.sprint_id"
            (selectionChange)="onSprintChange($event, element.id)"
          >
            <mat-option *ngFor="let sprint of sprintList" [value]="sprint.id">
              {{ sprint.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="assigned_to">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by Assignee"
      >
        Assignee
      </th>
      <td mat-cell *matCellDef="let element">
        <img
          class="assignee-icon"
          (click)="openAssigneeDialog(element)"
          src="{{
            userEntities[element.assigned_to]
              ? userEntities[element.assigned_to].avatar
              : '../../../../../assets/icons/dummy-avatar.png'
          }}"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="title_filter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label>Search Title</mat-label>
          <input
            id="title"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container matColumnDef="feature_filter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label>Search Feature</mat-label>
          <input
            id="feature"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container matColumnDef="sprint_filter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label>Search Sprint</mat-label>
          <input
            id="sprint"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container matColumnDef="stage_filter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label>Search Stage</mat-label>
          <input
            id="stage"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </th>
    </ng-container>

    <ng-container matColumnDef="assignee_filter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field appearance="standard">
          <mat-label>Search Assignee</mat-label>
          <input
            id="assignee"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. ium"
            #input
          />
        </mat-form-field>
      </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-header-row
      *matHeaderRowDef="[
        'title_filter',
        'feature_filter',
        'sprint_filter',
        'stage_filter',
        'assignee_filter'
      ]"
      class="example-second-header-row"
    ></tr>
    <tr
      mat-row
      (dblclick)="redirectToCard(row.id)"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
</cdk-virtual-scroll-viewport>

<!-- <table
  mat-table
  *ngIf="isDragabble"
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
  matSort
  (matSortChange)="announceSortChange($event)"
>
  <ng-container matColumnDef="title">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by Title"
    >
      Title
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.title }}
    </td>
  </ng-container>

  <ng-container matColumnDef="parent_id">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by Feature"
    >
      Feature
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="fill">
        <mat-label>Feature</mat-label>
        <mat-select
          [value]="element.parent_id"
          (selectionChange)="onFeatureChange($event, element.id)"
        >
          <mat-option *ngFor="let feature of featureList" [value]="feature.id">
            {{ feature.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>
  <ng-container matColumnDef="stageId">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by Stage"
    >
      Stage
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="fill">
        <mat-label>Stages</mat-label>
        <mat-select
          [value]="element.stageId"
          (selectionChange)="onStageChange($event, element.id)"
        >
          <mat-option *ngFor="let stage of stagesList" [value]="stage.id">
            {{ stage.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>
  <ng-container matColumnDef="sprint_id">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by Sprint"
    >
      Sprint
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="fill">
        <mat-label>Sprint</mat-label>
        <mat-select
          [value]="element.sprint_id"
          (selectionChange)="onSprintChange($event, element.id)"
        >
          <mat-option *ngFor="let sprint of sprintList" [value]="sprint.id">
            {{ sprint.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>
  <ng-container matColumnDef="assigned_to">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      sortActionDescription="Sort by Assignee"
    >
      Assignee
    </th>
    <td mat-cell *matCellDef="let element">
      <img
        class="assignee-icon"
        (click)="openAssigneeDialog(element.id)"
        src="{{
          userEntities[element.assigned_to]
            ? userEntities[element.assigned_to].avatar
            : '../../../../../assets/icons/dummy-avatar.png'
        }}"
      />
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    (dblclick)="redirectToCard(row.id)"
    cdkDrag
    *matRowDef="let row; columns: displayedColumns"
  ></tr>
</table> -->

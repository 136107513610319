import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';
import { createCard } from '../../store/actions/card.actions';
import {
  selectActiveOrgId,
  selectActiveProjectId,
  selectActiveSprintId,
} from '../../store/reducers';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { selectProjectPropertiesList } from '../../store/selectors/project-properties.selectors';
import { selectSprintList } from '../../store/selectors/sprint.selector';
import { selectCardListEntities } from '../../store/selectors/card.selector';
import { updateCard } from '../../store/actions/card.actions';
import { selectUserEntities } from '../../store/selectors/user.selectors';
import { IProfileResponse } from '../../models';
import { MatDialog } from '@angular/material/dialog';
import { CardAssigneeDialog } from '../card-assignee-dialog/card-assignee-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

@Component({
  selector: 'app-task-table-view',
  templateUrl: './task-table-view.component.html',
  styleUrls: ['./task-table-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskTableViewComponent implements AfterViewInit, OnInit {
  @Input() fromComponent: any = [];
  public activeSprintId: any;
  public stagesList: any = {};
  public featureList: any = [];
  public stagesObject: any = {};
  public sprintList: any = [];
  public sprintObject: any = {};
  public activeOrgId: any;
  public activeProjectId: any;
  public cardListEntities: any;
  public userEntities: any;
  public isDragabble: boolean = false;
  displayedColumns: string[] = [
    'title',
    'parent_id',
    'sprint_id',
    'stageId',
    'assigned_to',
  ];
  public titleUpdate = debounce(this.updateTitle, 700);
  public dataSource = new TableVirtualScrollDataSource([]);

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      });

    this.store
      .pipe(select(selectActiveSprintId), takeUntil(this.destroyed$))
      .subscribe((activeSprintId) => {
        this.activeSprintId = activeSprintId;
      });

    this.store
      .pipe(select(selectUserEntities), takeUntil(this.destroyed$))
      .subscribe((userEntities: { [id: number]: IProfileResponse }) => {
        this.userEntities = userEntities;
      });

    this.store
      .pipe(select(selectActiveProjectId), takeUntil(this.destroyed$))
      .subscribe((activeProjectId) => {
        if (activeProjectId) {
          this.activeProjectId = activeProjectId;
        }
      });

    this.store
      .pipe(select(selectSprintList), takeUntil(this.destroyed$))
      .subscribe(async (localsprintList) => {
        let tempSprintList = [];
        let tempSprintObject = {};
        for (let sprint of localsprintList) {
          sprint = { ...sprint };
          sprint.start_date = await this.transformDate(sprint.start_date);
          sprint.end_date = await this.transformDate(sprint.end_date);
          tempSprintObject[sprint.id] = sprint;
          tempSprintList.push(sprint);
        }
        this.sprintObject = tempSprintObject;
        this.sprintList = tempSprintList;
      });

    this.store
      .pipe(select(selectCardListEntities), takeUntil(this.destroyed$))
      .subscribe((entities) => {
        const localFeatureList = [];
        Object.keys(entities).map((key: string) => {
          if (entities[key].type === 'FEATURE') {
            localFeatureList.push(entities[key]);
          }
        });
        const localTaskList = Object.keys(entities)
          .map((key: string) => {
            if (entities[key].type === 'TASK') {
              if (
                this.fromComponent === 'productBacklog' &&
                entities[key].sprint_id === null
              ) {
                return entities[key];
              } else if (
                this.fromComponent === 'sprintList' &&
                entities[key].sprint_id === this.activeSprintId
              ) {
                return entities[key];
              }
              return undefined;
            }
          })
          .filter((item: any) => item)
          .sort((task1: any, task2: any) =>
            task1.title.toUpperCase().localeCompare(task2.title.toUpperCase())
          );
        this.dataSource = new TableVirtualScrollDataSource([...localTaskList]);
        this.featureList = localFeatureList;
        this.cardListEntities = entities;
        this.dataSource.filterPredicate = (record, filter) => {
          const filterValue = filter.slice(0, filter.indexOf('?'));
          const columnName = filter.slice(filter.indexOf('?') + 1);
          const localCaseValue = filterValue?.toLocaleLowerCase();
          if (
            columnName === 'global_filter' &&
            (record?.title?.toLocaleLowerCase()?.includes(localCaseValue) ||
              this.cardListEntities[record?.parent_id]?.title
                ?.toLocaleLowerCase()
                .includes(localCaseValue) ||
              (
                this.userEntities[record?.assigned_to]?.firstName +
                ' ' +
                this.userEntities[record?.assigned_to]?.lastName
              )
                ?.toLocaleLowerCase()
                ?.includes(localCaseValue) ||
              this.stagesObject[record?.stageId]?.title
                ?.toLocaleLowerCase()
                ?.includes(localCaseValue) ||
              (localCaseValue === 'unassigned' && record.assigned_to === null))
          ) {
            return record;
          } else if (
            columnName === 'title' &&
            record?.title?.toLocaleLowerCase()?.includes(localCaseValue)
          ) {
            return record;
          } else if (
            columnName === 'feature' &&
            this.cardListEntities[record?.parent_id]?.title
              ?.toLocaleLowerCase()
              .includes(localCaseValue)
          ) {
            return record;
          } else if (columnName === 'sprint' && localCaseValue?.length > 0) {
            return false;
          } else if (columnName === 'sprint' && localCaseValue.length === 0) {
            return record;
          } else if (
            columnName === 'stage' &&
            this.stagesObject[record?.stageId]?.title
              ?.toLocaleLowerCase()
              ?.includes(localCaseValue)
          ) {
            return record;
          } else if (
            columnName === 'assignee' &&
            (
              this.userEntities[record?.assigned_to]?.firstName +
              ' ' +
              this.userEntities[record?.assigned_to]?.lastName
            )
              ?.toLocaleLowerCase()
              ?.includes(localCaseValue)
          ) {
            return record;
          }
        };
      });

    this.store
      .pipe(select(selectProjectPropertiesList), takeUntil(this.destroyed$))
      .subscribe((propertiesList) => {
        const localStages = {};
        this.stagesList = propertiesList
          .filter((property: any) => {
            if (property?.id?.includes('STAGE') === true) {
              localStages[property.id] = property;
              return true;
            }
            return false;
          })
          .sort(
            (property1: any, property2: any) =>
              property1.position - property2.position
          );
        this.stagesObject = localStages;
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  public async transformDate(date: any) {
    if (date) {
      const dateArray = date?.split('T');
      return dateArray[0];
    }
  }

  public onSprintChange(event, taskId) {
    const body = { ...this.cardListEntities[taskId], sprint_id: event.value };
    this.store.dispatch(updateCard({ cardId: taskId, body }));
  }

  public onStageChange(event, taskId) {
    const body = { ...this.cardListEntities[taskId], stageId: event.value };
    this.store.dispatch(updateCard({ cardId: taskId, body }));
  }

  public onFeatureChange(event, taskId) {
    const body = { ...this.cardListEntities[taskId], parent_id: event.value };
    this.store.dispatch(updateCard({ cardId: taskId, body }));
  }

  public openAssigneeDialog(task) {
    this.dialog.open(CardAssigneeDialog, {
      data: task,
      width: '450px',
    });
  }

  public applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = `${filterValue.trim().toLowerCase()}?${
      event?.target?.id
    }`;
  }

  public redirectToCard(id: string) {
    const url = this.router.createUrlTree([
      `orgs/${this.activeOrgId}/projectId/${this.activeProjectId}/card/${id}`,
    ]);
    window.open(url.toString(), '_blank');
  }

  public addNewCard() {
    this.store.dispatch(
      createCard({
        body: {
          title: 'untitled task',
          project_id: this.activeProjectId,
          sprint_id: this.activeSprintId,
          type: 'TASK',
          requestId: uuidv4(),
        },
      })
    );
  }

  public updateTitle(element) {
    const body = { ...this.cardListEntities[element.id], title: element.title };
    this.store.dispatch(updateCard({ cardId: element.id, body }));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.dataSource.data,
      event.previousIndex,
      event.currentIndex
    );
    this.dataSource.data = [...this.dataSource.data];
  }
}

import { Injectable } from '@angular/core'
import {
  RealTimeResponse,
  IProjectRequest,
  IProjectResponse
} from '@app/models'
import { PROJECT_APIS } from '../apis/project.apis'
import { HttpWrapperService } from './http-wrapper.service'

@Injectable() 
export class ProjectService {
  constructor(private httpWrapperService: HttpWrapperService) {}

  public async fetchSingleProject(
    projectId: string
  ): Promise<RealTimeResponse<IProjectResponse>> {
    const url = `${PROJECT_APIS.BASE_URL_PROJECT}/${projectId}`;
    try {
      const res = await this.httpWrapperService
        .get(url, {})
      return new RealTimeResponse<IProjectResponse>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<IProjectResponse>().error(err.error)
    }
  }

  public async fetchAllProjects(
    orgId: string
  ): Promise<RealTimeResponse<IProjectResponse[]>> {
    const url = PROJECT_APIS.FETCH_ALL_PRJECTS.replace(':orgId', String(orgId))
    try {
      const res = await this.httpWrapperService
        .get(url, {})
      return new RealTimeResponse<IProjectResponse[]>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<IProjectResponse[]>().error(err.error)
    }
  }

  public async createProject(
    body: any,
  ): Promise<RealTimeResponse<any[]>> {
    const url = PROJECT_APIS.BASE_URL_PROJECT;
    try {
      const res = await this.httpWrapperService
        .post(url, body)
      return new RealTimeResponse<any[]>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<any[]>().error(err.error)
    }
}

  public async updateProject(
    projectId: string,
    body: any,
  ): Promise<RealTimeResponse<any[]>> {
    const url = `${PROJECT_APIS.BASE_URL_PROJECT}/${projectId}`;
    try {
      const res = await this.httpWrapperService
        .patch(url, body)
      return new RealTimeResponse<any[]>().success(res.body)
    } catch (err) {
      return new RealTimeResponse<any[]>().error(err.error)
    }
  }
}
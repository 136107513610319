<h1 mat-dialog-title>Create New Sprint</h1>
<div mat-dialog-content>
  <form
    class="form-body"
    [formGroup]="addSprintForm"
    (ngSubmit)="saveDetails()"
  >
    <mat-form-field class="field" appearance="standard">
      <input
        name="title"
        matInput
        type="text"
        placeholder="Name of the Sprint"
        formControlName="title"
      />
    
    </mat-form-field>
    <mat-form-field appearance="standard">
      <input
        name="startDate"
        matInput
        type="date"
        placeholder="Start Date"
        formControlName="startDate"
      />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <input
        name="endDate"
        matInput
        type="date"
        placeholder="End Date"
        formControlName="endDate"
      />
     
    </mat-form-field>
    <div class="button-container">
      <button
        class="cancle-button"
        mat-stroked-button
        type="reset"
        color="primary"
        (click)="onCancleClick()"
      >
        Cancle
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="add-button"
      >
        Add
      </button>
    </div>
  </form>
</div>

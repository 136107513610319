import { Component, Input, OnInit } from '@angular/core';
import {
  combineLatest,
  Observable,
  ReplaySubject,
  take,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { select, Store } from '@ngrx/store';
import { debounce } from 'lodash';
import {
  selectActiveCard,
  selectActiveProjectAndOrg,
  selectCardListEntities,
} from '../../store/selectors/card.selector';
import { ICardResponse } from '../../models/interfaces/card.interface';
import { updateCard } from '../../store/actions/card.actions';
import { IProfileResponse, IProjectResponse } from '../../models';
import { selectUserEntities } from '../../store/selectors/user.selectors';
import { selectProjectList, selectProjectListEntities } from '../../store/selectors/project.selectors';
import { AppCookieService } from "../../store/services/cookie.service";
import { environment } from '@app/src/environments/environment'
import { selectProjectPropertiesList } from '../../store/selectors/project-properties.selectors';
import { loadAllSprints, loadAllSprintsSuccess } from '../../store/actions/sprint.actions';
import { selectSprintListState } from '../../store/selectors/sprint.selector';
import { selectCurrentUser, selectCurrentUserId } from '../../store/selectors/user.selectors';
import { selectActiveOrgId } from '../../store/reducers';
import { DelveService } from '../../store/services/delve.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { selectProjectUserList } from '../../store/selectors/project-user.selectors';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss'],
})
export class CardDetailComponent implements OnInit {
  public titleUpdate = debounce(this.updateTitle, 700);
  public activeCard: ICardResponse;
  public activeProjectId: string;
  //public activeStringId: string;
  public activeProject: IProjectResponse;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public featuresListEnities: any;
  public projectEntities: any;
  public cardListEntities: any;
  public selectedFeature: string;
  public selectedAssignee: string;
  public moduleListEntities: any;
  public showMore: boolean = false;
  public properties: any;
  public stages: any;
  public sprintList: any;
  public currentUser: any;
  public activeOrgId: string; 
  public rtcUrl: string = environment.RTC_URL;
  public formGroup : FormGroup;
  public filteredOptions;
  public currentUserId: any;
  public memberValue: any = '';
  public projectUsers:any;
  
  public accessToken: string;
  userEntities: { [id: number]: IProfileResponse };
  constructor(private store: Store, private cookieService: AppCookieService,private delveService: DelveService, private fb : FormBuilder) {
  }

  @Input() set inputCardIdValue(value: string) {
    if(value) {
      this.store
      .pipe(select(selectActiveCard(value)), takeUntil(this.destroyed$))
      .subscribe((card: any) => {
        if (card) {
          this.activeCard = { ...card };
          this.selectedFeature = card?.parent_id;
          this.selectedAssignee = card?.assigned_to;
          this.findAndSetSingleUser(card.created_by);
          if (card?.assigned_to) {
            this.findAndSetSingleUser(card.assigned_to);
          }
        }
      });
    }
  };

  ngOnInit(): void {
    this.initForm();
    this.store
      .pipe(select(selectActiveProjectAndOrg), takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        const { projectId, orgId } = data;
        if (projectId && orgId) {
          this.delveService.search(
            projectId,
            this.currentUserId,
            `${environment.environment}-sprint`,
            environment.DELVE_API_KEY,
            {
              terms: {
                type: ['SPRINT', 'MODULE', 'FEATURE', 'TASK'],
                orgId: [orgId],
                project_id: [projectId]
              }
            }
          ).subscribe((response: any) => {
            console.log('response', response);
            const modules = {};
            const features = {};
            const tasks = {};
            const sprints = {};
            response.forEach((data: any) => {
              const key = data._source.id;
              const type = data._source.type;
              if (type === 'MODULE') {
                modules[key] = data._source;
              } else if (type === 'FEATURE') {
                features[key] = data._source;
              } else if (type === 'TASK') {
                tasks[key] = data._source;
              } else if (type === 'SPRINT') {
                sprints[key] = data._source;
              }
            })
            this.sprintList = sprints;
            this.cardListEntities = tasks;
            this.moduleListEntities = modules;
            this.featuresListEnities = features;
          })
        }
      })

    this.store
      .pipe(select(selectUserEntities), takeUntil(this.destroyed$))
      .subscribe((userEntities: { [id: number]: IProfileResponse }) => {
        this.userEntities = userEntities;
      });
    
    this.store
      .pipe(select(selectCurrentUserId), takeUntil(this.destroyed$))
      .subscribe((userId) => {
        this.currentUserId = userId;
      });

      this.store
      .pipe(select(selectProjectUserList), takeUntil(this.destroyed$))
      .subscribe((projectUserList) => {
        this.projectUsers=projectUserList;
      });

    this.store
      .pipe(select(selectProjectListEntities), takeUntil(this.destroyed$))
      .subscribe((projectList) => {
        this.projectEntities = projectList;
      });
    
    this.store
      .pipe(select(selectProjectPropertiesList), takeUntil(this.destroyed$))
      .subscribe((propertiesList) => {
        this.properties = propertiesList
          .filter((property: any) => property?.id?.includes('STAGE') === false)
          .sort(
            (property1: any, property2: any) =>
              property1.position - property2.position
          );

        this.stages = propertiesList
          .filter((property: any) => property?.id?.includes('STAGE') === true)
          .sort(
            (property1: any, property2: any) =>
              property1.position - property2.position
          );
      });

    this.store
      .pipe(select(selectCurrentUser), takeUntil(this.destroyed$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
      })

    this.store
      .pipe(select(selectActiveOrgId), takeUntil(this.destroyed$))
      .subscribe((activeOrgId) => {
        this.activeOrgId = activeOrgId;
      })

      this.store
        .pipe(select(selectCardListEntities), takeUntil(this.destroyed$))
        .subscribe((entities) => {
          const modules = {};
          const features = {};
          const tasks = {};
          Object.keys(entities).map((key: string) => {
            if (entities[key].type === 'MODULE') {
              modules[key] = entities[key];
            } else if (entities[key].type === 'FEATURE') {
              features[key] = entities[key];
            } else if (entities[key].type === 'TASK') {
              tasks[key] = entities[key];
            }
          });
          this.cardListEntities = Object.assign({}, this.cardListEntities, tasks);
          this.moduleListEntities = Object.assign({}, this.moduleListEntities, modules);
          this.featuresListEnities = Object.assign({}, this.featuresListEnities, features);
        });

    this.accessToken = this.cookieService.get(
      `${environment?.environment}-feathers-jwt`
    );
  }

  public updateTitle() {
    const body = { ...this.activeCard };
    this.store.dispatch(updateCard({ cardId: this.activeCard.id, body }));
  }

  public onFeatureChange(event) {
    const body = { ...this.activeCard, parent_id: event.value };
    this.store.dispatch(updateCard({ cardId: this.activeCard.id, body }));
  }

  public onAssigneeChange(event) {
    const value=event?.source?.id?._source?.userId
    const body = { ...this.activeCard, assigned_to: value };
    this.store.dispatch(updateCard({ cardId: this.activeCard.id, body }));
  }

  public onSprintChange(event) {
    const body = { ...this.activeCard, sprint_id: event.value };
    this.store.dispatch(updateCard({ cardId: this.activeCard.id, body }));
  }

  public toggleShowMore() {
    this.showMore = !this.showMore;
  }

  public updateCardProperty(event: any) {
    this.store.dispatch(updateCard({cardId: this.activeCard.id, body: {
      key: event.id,
      value: event.value,
    }}));
  }

  public onStageChange(event) {
    const body = { ...this.activeCard, stageId: event.value };
    this.store.dispatch(updateCard({ cardId: this.activeCard.id, body }));
  }

  public initForm(){
    this.formGroup = this.fb.group({
      'employee' : ['']
    })
    this.formGroup.get('employee').valueChanges
    .pipe(debounceTime(200))
    .subscribe(response => {
      console.log('entered data is ', response);
      if(response && response.length){
        this.getNames(response);
      } else {
        this.filteredOptions = [];
      }
      
    })
  }
  

  public getNames(value){
    this.delveService.search(value,
      this.currentUserId,
      `${environment.environment}-space`,
      environment.DELVE_API_KEY,
      {
        terms: {
          type: ['U'],
          orgId: [this.activeOrgId],
        },
        must_not: {
          isEnabled: [false],
        },
      }).subscribe(response => {
      this.filteredOptions=response;
    })
  }

  public findAndSetSingleUser(userId: string) {
    this.delveService.search(
      userId,
      this.currentUserId,
      `${environment.environment}-space`,
      environment.DELVE_API_KEY,
      {
        terms: {
          type: ['U'],
          userId: [userId]
        }
      }
    ).subscribe((userData: any) => {
      const user = userData[0]?._source;
      if (user) {
        const newUser = {
          [user.userId as number]: {
            ...user,
            firstName: user.title,
            lastName: ''
          }
        }
        this.userEntities = Object.assign({}, this.userEntities, newUser);
      }
    })
  }
}

import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { DOCUMENT } from '@angular/common'
@Injectable({ providedIn: 'root' })
export class LocalService {
  public sidebarCollapsed$ = new Subject<boolean>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
  ) {}

  public changeQueryParam(param: any) {
    const queryParams = param
    const urlTree = this.router.createUrlTree([], {
      queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true,
    })
    this.router.navigateByUrl(urlTree)
  }

  public toggleMobileMenu() {
    if (this.document.body.classList.contains('show-mobile-menu')) {
      this.document.body.classList.remove('show-mobile-menu');
    } else {
      this.document.body.classList.add('show-mobile-menu');
    }
  }

  public goToCard(cardId: string, activeOrgId: string, projectId: string) {
    window.open(`/orgs/${activeOrgId}/projectId/${projectId}/card/${cardId}`)
  }

  public toggleSidebarCollapsed(value: boolean) {
    this.sidebarCollapsed$.next(value);
  }

  public goToCardDetail( activeOrgId: string, projectId: string, cardId: string) {
    window.open(`/orgs/${activeOrgId}/project/${projectId}?cardId=${cardId}`)
  }
}

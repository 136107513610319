import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on, Action } from "@ngrx/store";

import * as OrgActions from "../actions/org.actions";
import { IOrgResponse } from "@app/models";

export const orgFeatureKey = "org";

export interface OrgState extends EntityState<IOrgResponse> {}

export const adapter: EntityAdapter<IOrgResponse> =
  createEntityAdapter<IOrgResponse>();

export const initialState: OrgState = adapter.getInitialState({});

export const orgReducer = createReducer(
  initialState,

  on(OrgActions.loadOrgSuccess, (state, action) => {
    if (action.payload?.data) {
      const orgData = action.payload.data;
      return adapter.upsertOne(orgData, state);
    }
    return state;
  }),

  on(OrgActions.loadUserOrgsSuccess, (state, action) => {
    if (action.payload?.data?.orgs) {
      const orgData = action.payload.data?.orgs;
      return adapter.setAll(orgData, state);
    }
    return state;
  }),

  on(OrgActions.updateOrgViaEvent, (state, action) => {
    const org = action.org;
    if (org) {
      return adapter.updateOne({ id: org.id, changes: org }, state);
    }
    return state;
  })
);

export function reducer(orgState: OrgState | undefined, action: Action) {
  return orgReducer(orgState, action);
}

import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { EMPTY } from 'rxjs'
import { map, catchError, switchMap } from 'rxjs/operators'
import * as SprintActions from '../actions/sprint.actions'
import { SprintService } from '../services/sprint.service'

@Injectable()
export class SprintEffects {
  constructor(private actions$: Actions, private sprintService: SprintService) {}

  loadAllSprints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SprintActions.loadAllSprints),
      switchMap((payload) => {
        return this.sprintService.fetchAllSprints(payload.projectId)
      }),
      map((allSprints) => ({
        type: SprintActions.loadAllSprintsSuccess.type,
        payload: allSprints,
      })),
      catchError(() => EMPTY)
    )
  )

  loadASprintById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SprintActions.loadSprintById),
      switchMap((payload) => {
        console.log("effect for load a sprint by Id : ",payload);
        return this.sprintService.fetchASprintById(payload.sprintId)
      }),
      map((sprint) => ({
        type: SprintActions.loadSprintByIdSuccess.type,
        payload: sprint,
      })),
      catchError(() => EMPTY)
    )
  )

  setActiveSprint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SprintActions.setActiveSprint),
      switchMap((payload) => {
        console.log("effect for set active sprint : ",payload);
        return payload.sprintId
      }),
      map((sprint) => ({
        type: SprintActions.setActiveSprintSuccess.type,
        payload: sprint,
      })),
      catchError(() => EMPTY)
    )
  )

  createASprint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SprintActions.createSprint),
      switchMap((payload) => {
        console.log("effect for create a sprint by Id : ",payload);
        return this.sprintService.createASprint(payload.model)
      }),
      map((sprint) => ({
        type: SprintActions.createSprintSuccess.type,
        payload: sprint,
      })),
      catchError(() => EMPTY)
    )
  )

  updateASprint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SprintActions.updateSprint),
      switchMap((payload) => {
        console.log("effect for update a sprint by Id : ",payload);
        return this.sprintService.updateASprint(payload.id, payload.model)
      }),
      map((sprint) => ({
        type: SprintActions.updateSprintSuccess.type,
        payload: sprint,
      })),
      catchError(() => EMPTY)
    )
  )
}
